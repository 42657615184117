import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/default.webp";
import calender from "../../Assets/Image/userDetails/calendar-2.png";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import DropDownComponent from "../../Component/DropDownComponent";
import view from "../../Assets/Image/newView.webp";

const SubscriptionDataTab = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [filter, setFilter] = useState("status != -1 and role NOT IN (3,4)");

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.profile_img
                ? apiList.baseURL + cell?.row?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "Plan",
      headerName: "Plan",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return (
          <span
            style={{
              color: "#292D32",
              background: "#FFDFD4",
              padding: "8px 14px",
              borderRadius: "8px",
            }}
          >
            Starter
          </span>
        );
      },
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100px",
            }}
          >
            <img
              src={calender}
              alt="profile"
              style={{ height: "21px", width: "21px" }}
            />
            <span style={{ fontSize: "14px" }}>01/01/2024</span>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100px",
            }}
          >
            <img
              src={clock}
              alt="profile"
              style={{ height: "21px", width: "21px" }}
            />
            <span style={{ fontSize: "14px" }}>02:00 AM</span>
          </Box> */}
        </div>
      ),
    },

    {
      field: "status",
      headerName: "View",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const status = cell?.row?.status;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={view}
                // onClick={() => {
                //   navigate(RouteList.userDetails, {
                //     state: {
                //       userId: cell?.row?.id,
                //     },
                //   });
                // }}
                style={{ height: "29px", width: "29px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filterOption = [
    { label: "All", value: "status != -1 and role NOT IN (3,4)" },
    // { label: "Free", value: "status != -1 and role = 1 " },
    // { label: "Premium", value: "status != -1 and role = 2 " },
    // { label: "Suspended", value: "status = 0 and role != 3" },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch)
      {
        setPage(0);
        setData([]);
      }
      let arg =
        search?.length > 0
          ? `${filter} and (username LIKE '%25${search?.trim()}%25' OR email LIKE '%25${search?.trim()}%25' OR phno LIKE '%25${search?.trim()}%25')`
          : filter;
      const res = await AXIOS.get(
        `${apiList.user.getAll}&arg=${arg} &order=created_at desc&skip=${count}&limit=${rowsPerPage}&total=1`
      );
      if (res?.s)
      {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box>
      </Box>
      <DataListingComponent
        data={data}
        PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
        loading={loading}
        totalData={totalData}
        page={page}
        columns={columns}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

export default SubscriptionDataTab;
