import { Box, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { AXIOS } from "../../Component/apiHelper";
import view from "../../Assets/Image/newView.webp";
import { apiList } from "../../Component/apiList";
import defaultUserImage from "../../Assets/Image/default.webp";
import { toast } from "react-toastify";
import back from "../../Assets/Image/back.png";
// import logo from "../../Assets/Image/question.webp";
// import ConfirmationModal1 from "../../Component/ConfirmModal1";
import { useLocation, useNavigate } from "react-router-dom";
// import { RouteList } from "../../Component/RoutesList";
// import app from "../../Assets/Image/User Block-1.webp";
// import rej from "../../Assets/Image/User Block.webp";
// import dlt from "../../Assets/Image/delete.webp";
// import moment from "moment";
import { RouteList } from "../../Component/RoutesList";
import { StatusList } from "../../Component/StatusList";
// import BasicMenu from "../../Component/MenuOptioinComponent";
const CommunityReportDetails = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState(99);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const location = useLocation();

  // const handleAction = async (id) => {
  //   try {
  //     const res = await AXIOS.get(`${apiList.rating.deleteById}?id=${id}`);
  //     if (res?.s) {
  //       toast.error("Deleted successfully");
  //       getData(
  //         page,
  //         rowsPerPage,
  //         filter,
  //         filterRef.current,
  //         debouncedSearchTerm,
  //         searchRef.current
  //       );
  //     } else {
  //       toast.error(res?.m);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const filterOption = [
    { label: "All", value: 9 },
    {
      label: "Pending",
      value: "0",
    },
    { label: "Verified", value: "1" },
    { label: "Flagged", value: "-2" },
    { label: "Suspended", value: "-3" },
    { label: "Banned", value: "-4" },
  ];

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          // onClick={() => handleDetailsNav(cell?.row)}
        >
          <img
            src={
              cell?.row?.report_by_detail?.profile_img
                ? apiList.baseURL + cell?.row?.report_by_detail?.profile_img
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.report_by_detail?.username,
    },

    {
      field: "phno",
      headerName: "Number",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.report_by_detail?.phno_cc &&
          cell?.row?.report_by_detail?.phno
          ? cell?.row?.report_by_detail?.phno_cc +
              " " +
              cell?.row?.report_by_detail?.phno
          : "-";
      },
    },
     {
          field: "message",
          headerName: "Message",
          minWidth: 200,
          flex: 1,
          headerClassName: "super-app-theme--header",
          headerAlign: "center",
          display: "flex",
          align: "center",
          renderCell: ({ row }) => {
            let x = row?.message_type;
            let src = x === 3 ? row?.thumb : x === 2 && row?.media;
            if (x === 1) {
              return row?.message;
            } else {
              return (
                <img
                  src={src && apiList.baseURL + src}
                  width={40}
                  height={40}
                  alt="video thumbnail"
                  style={{
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    window.open(`${apiList.baseURL + row?.media}`, "_blank")
                  }
                />
              );
            }
          },
        },
    {
      field: "type",
      headerName: "Reason",
      width: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
    },

    {
      field: "AccountStatus",
      headerName: "Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const { status } = cell?.row?.report_by_detail;
        const lable = StatusList?.find((x) => x?.code == status);
        return <span style={{ color: lable?.color }}>{lable?.name}</span>;
      },
    },
    {
      field: "status",
      headerName: "Action",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              {/* <img
                src={deleted}
                style={{ height: "29px", width: "29px" }}
                alt="delete"
                onClick={() => {
                  setOpen(true);
                  setActionParams(cell?.row?.id);
                }}
              /> */}

              <img
                src={view}
                // onClick={() => {
                //   setPostDetailsId(cell?.row?.id);
                //   setOpenDetailsModal(cell?.row);
                // }}
                onClick={() =>
                  navigate(RouteList.userDetails, {
                    state: { userId: cell?.row?.report_by_detail?.id },
                  })
                }
                style={{ height: "29px", width: "29px" }}
                alt="View"
              />
            </Box>
          </Box>
        );
      },
    },
    // {
    //     field: "Action",
    //     headerName: "Action",
    //     width: 100,
    //     headerClassName: "super-app-theme--header",
    //     headerAlign: "center",
    //     display: "flex",
    //     align: "center",
    //     renderCell: (cell) => {
    //         const menuOptions = [{
    //             action: () => handleDetailsNav(cell.row),
    //             title: "View User",
    //             isDisabled: false,
    //             // isActive: true ,
    //         }, {
    //             action: () => handleReportNav(data),
    //             title: "View Report Details",
    //             isDisabled: false,
    //             // isActive: user_details_access ? true : false,
    //         },
    //             //  {
    //             //   // action: () => handleDetailsNav(data),
    //             //   title: "Action",
    //             //   isDisabled: false,
    //             //   // isActive: user_details_access ? true : false,
    //             // }
    //         ];
    //         return (
    //             <Box className="flexCenter" sx={{ height: "100%" }}>
    //                 <BasicMenu rowData={cell?.row} options={menuOptions} />
    //             </Box>
    //         );
    //     },
    // },
  ];
  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (filter !== prevFilter || search !== prevSearch) {
        setPage(0);
        setData([]);
      }
      let arg = `?skip=${count}&limit=${rowsPerPage}&message_id=${location?.state?.id}`;

      if (search?.length > 0) {
        arg += `&search=${search?.trim()}`;
      }

      let url = `${apiList.CommunityModeration.getDetailsList}${arg}`;
      const res = await AXIOS.get(url);
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        setData([]);
        setPage();
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 4,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img
              src={back}
              style={{
                height: "30px",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
              alt=""
            />
            Details
          </h2>
        </Typography>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          m: "auto",
          top: {
            xs: "-105px",
            sm: "-90px",
            md: "-90px",
            lg: "-55px",
            xl: "-55px",
          },
          right: { lg: "0px" },
          minWidth: "300px",
          height: "50px",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search by name"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        <Box sx={{}}>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          />
        </Box>
      </Box> */}
      <Box sx={{ height: "calc(100vh - 140px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />

        {/* <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          logo={logo}
          onConfirm={() => handleAction(actionParams)}
          title="Confirm Delete Action"
          message="Are you sure want to delete?"
          confirmText="Confirm"
          cancelText="Cancel"
        /> */}
      </Box>
    </Box>
  );
};

export default CommunityReportDetails;
