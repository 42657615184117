import React, { useEffect, useRef, useState } from "react";
import PendingScreen from "../PendingScreen";
import { Box, Tooltip, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { RouteList } from "../../Component/RoutesList";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import moment from "moment";

const FinanceManagement = () => {
    const PAGE_SIZE_OPTIONS = [15];
    const [open, setOpen] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [postDetailsId, setPostDetailsId] = useState(0);
    const [actionParams, setActionParams] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
    const [totalData, setTotalData] = useState(0);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [filter, setFilter] = useState(99);
    const [from_date, setFromDate] = useState("");
    const [to_date, setToDate] = useState("");

    const columns = [
        {
            field: "id",
            headerName: "Sr.",
            width: 60,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            filter: true,
            sort: true,
            //   renderCell: (cell) => {
            //     return cell?.row?.rowid + page * rowsPerPage;
            //   },
        },
        {
            field: "t_id",
            headerName: "Transaction ID",
            width: 220,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            filter: true,
            sort: true,
            renderCell: (cell) => '8424214fgfg54',
        },

        {
            field: "date",
            headerName: "Date",
            width: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            filter: true,
            sort: true,
            //   renderCell: (params) => (
            //     <Box
            //       sx={{
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "start",
            //         overflow: "hidden",
            //         textOverflow: "ellipsis",
            //         whiteSpace: "nowrap",
            //         width: "100%",
            //         height: "100%",
            //       }}
            //     >
            //       <Tooltip title={params.value || ""} arrow>
            //         <Typography
            //           variant="body2"
            //           sx={{
            //             overflow: "hidden",
            //             textOverflow: "ellipsis",
            //             whiteSpace: "nowrap",
            //             width: "100%",
            //           }}
            //         >
            //           {params.value}
            //         </Typography>
            //       </Tooltip>
            //     </Box>
            //   ),
        },
        {
            field: "time",
            headerName: "Time",
            width: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            filter: true,
            sort: true,
            //   renderCell: (params) => (
            //     <Box
            //       sx={{
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "start",
            //         overflow: "hidden",
            //         textOverflow: "ellipsis",
            //         whiteSpace: "nowrap",
            //         width: "100%",
            //         height: "100%",
            //       }}
            //     >
            //       <Tooltip title={params.value || ""} arrow>
            //         <Typography
            //           variant="body2"
            //           sx={{
            //             overflow: "hidden",
            //             textOverflow: "ellipsis",
            //             whiteSpace: "nowrap",
            //             width: "100%",
            //           }}
            //         >
            //           {params.value}
            //         </Typography>
            //       </Tooltip>
            //     </Box>
            //   ),
        },

        {
            field: "user",
            headerName: "Username",
            minWidth: 220,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            filter: true,
            sort: true,
            flex: 1,
            //   renderCell: (cell) => cell?.row?.category_name ?? "-",
        },





        {
            field: "amount",
            headerName: "Amount",
            width: 220,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: (cell) => (cell?.row?.amount ? "$" + cell?.row?.amount : "-"),
        },
        {
            field: "status",
            headerName: 'Status',
            width: 220,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
        },
        {
            field: "payment_method",
            headerName: 'Payment Method',
            width: 220,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
        },


    ];

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleDeletePost = async (id) => {
        try
        {
            setLoading(true);
            const res = await AXIOS.get(`${apiList.list.delete}?feed_id=${id}`);
            if (res?.s)
            {
                toast.error("Deleted successfully");
                getData(
                    page,
                    rowsPerPage,
                    filter,
                    filterRef.current,
                    debouncedSearchTerm,
                    searchRef.current,
                    from_date,
                    fromDateRef.current,
                    to_date,
                    toDateRef.current
                );
                setLoading(false);
            } else
            {
                toast.error(res?.m);
                setLoading(false);
            }
        } catch (error)
        {
            console.log(error);
            setLoading(false);
        }
    };

    const filterOption = [
        { label: "All", value: 9 },
        { label: "Active", value: "0" },
        { label: "Expired", value: "1" },
    ];

    const getData = async (
        page = 0,
        rowsPerPage = PAGE_SIZE_OPTIONS[0],
        filter,
        prevFilter,
        search,
        prevSearch,
        from_date,
        prevFromDate,
        to_date,
        prevToDate
    ) => {
        try
        {
            const count = page * rowsPerPage;
            setLoading(true);
            if (
                filter !== prevFilter ||
                search !== prevSearch ||
                from_date !== prevFromDate ||
                to_date !== prevToDate
            )
            {
                setPage(0);
                setData([]);
            }
            // let arg = search?.length > 0 ? `${filter} and search=${search}` : filter;
            //   let arg =
            //     search?.length > 0
            //       ? `?search=${search}&skip=${count}&limit=${rowsPerPage}`
            //       : `?skip=${count}&limit=${rowsPerPage}`;

            //   if (filter !== 9 && filter !== 99) {
            //     arg += `&is_expired=${filter}`;
            //   }

            //   if (from_date && to_date) {
            //     if (moment(to_date).isSameOrAfter(moment(from_date))) {
            //       arg += `&from_date=${from_date} &to_date=${to_date}`;
            //     } else {
            //       toast.error("last date must be greater than start date");
            //       setToDate("");
            //     }
            //   }

            //   const res = await AXIOS.get(`${apiList.report.getAllFeed}${arg}&type=1`);
            //   if (res?.s) {
            if (true)
            {
                // const updatedData = res?.r;
                // const serialData = updatedData?.map((x, i) => {
                //   return { ...x, rowid: i + 1 };
                // });
                setData(dummyData);
                // page === 0 && setTotalData(res?.total ?? 0);
                page === 0 && setTotalData(5);
                setLoading(false);
            } else
            {
                setTotalData(0);
                setPage(0);
                setData([]);
                // toast.warn(res?.m);
                setLoading(false);
            }
        } catch (error)
        {
            setLoading(false);
            console.log(error);
        }
    };
    // const handleDetailsNav = (data) => {
    //     navigate(RouteList.listDetails, {
    //         state: {
    //             id: data?.feed_id,
    //         },
    //     });
    // };
    // const handleReportNav = (data) => {
    //     navigate(RouteList.userreportdetails, {
    //         state: {
    //             id: data?.id,
    //             type: 2,
    //         },
    //     });
    // };
    const searchRef = useRef(debouncedSearchTerm);
    const filterRef = useRef(filter);
    const fromDateRef = useRef(filter);
    const toDateRef = useRef(filter);

    useEffect(() => {
        getData(
            page,
            rowsPerPage,
            filter,
            filterRef.current,
            debouncedSearchTerm,
            searchRef.current,
            from_date,
            fromDateRef.current,
            to_date,
            toDateRef.current
        );
        searchRef.current = debouncedSearchTerm;
        filterRef.current = filter;
        fromDateRef.current = from_date;
        toDateRef.current = to_date;
    }, [debouncedSearchTerm, filter, to_date, rowsPerPage, page]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const dummyData = [
        {
            id: "1",
            date: "2025-02-07",
            time: "10:15 AM",
            user: "John Doe",
            amount: 250.0,
            status: "Completed",
            payment_method: "Credit Card",
        },
        {
            id: "2",
            date: "2025-02-07",
            time: "11:30 AM",
            user: "Jane Smith",
            amount: 150.5,
            status: "Pending",
            payment_method: "PayPal",
        },
        {
            id: "3",
            date: "2025-02-06",
            time: "2:45 PM",
            user: "Alice Johnson",
            amount: 500.0,
            status: "Failed",
            payment_method: "Bank Transfer",
        },
        {
            id: "4",
            date: "2025-02-05",
            time: "9:20 AM",
            user: "Michael Brown",
            amount: 300.75,
            status: "Completed",
            payment_method: "Debit Card",
        },
        {
            id: "5",
            date: "2025-02-04",
            time: "6:10 PM",
            user: "Emily Wilson",
            amount: 200.0,
            status: "Completed",
            payment_method: "Credit Card",
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <Box className="flexBetween">
                <Typography>
                    <h2> Financial Management </h2>
                </Typography>
            </Box>
            {/* <Box
              sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
              }}
          >
              <Box
                  sx={{
                      maxWidth: "470px",
                      width: "100%",
                  }}
              >
                  <input
                      type="search"
                      name="search"
                      value={searchTerm?.trimStart()}
                      placeholder="Search by name, category and email"
                      style={{
                          padding: "13px",
                          height: "100%",
                          borderRadius: "12px",
                          border: "1px solid #FD5D29",
                          backgroundColor: "#FFF6F3",
                          color: "#FD5D29",
                          width: "100%",
                      }}
                      onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
                      id="search"
                  />
              </Box>
              <Box
                  sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "center",
                      alignItems: "center",
                  }}
              >
                  <DropDownComponent
                      value={accountFilter}
                      options={AccountFilterOption}
                      modal={false}
                      handleChange={handleAccountFilterChange}
                      placeholder={"Account"}
                      check={false}
                      bgColor={"#fd5d2924"}
                      color={"#fd5d29"}
                  />

                  <DropDownComponent
                      value={TypeFilter}
                      options={TypeFilterOption}
                      modal={false}
                      handleChange={handleTypeFilterChange}
                      placeholder={"Action"}
                      check={false}
                      bgColor={"#fd5d2924"}
                      color={"#fd5d29"}
                  />
                  <DropDownComponent
                      value={filter}
                      options={filterOption}
                      modal={false}
                      handleChange={handleFilterChange}
                      placeholder={"Status"}
                      check={false}
                      bgColor={"#fd5d29"}
                      color={"#FFF"}
                  />
                 
              </Box>
          </Box> */}
            <Box sx={{ height: "calc(100vh - 120px)" }}>
                <DataListingComponent
                    data={data}
                    PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
                    loading={loading}
                    totalData={totalData}
                    page={page}
                    columns={columns}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </Box>
        </Box>
    );
};

export default FinanceManagement;
