import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useState } from "react";
import { apiList } from "../../Component/apiList";
import calender from "../../Assets/Image/userDetails/calendar-2.png";
import clock from "../../Assets/Image/userDetails/clock.png";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

const UserPaymentHistory = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);

    const columns = [
        {
            field: "rowid",
            headerName: "Sr.",
            width: 60,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            renderCell: (cell) => {
                return cell?.row?.rowid + page * rowsPerPage;
            },
        },
        {
            field: "trans_id",
            headerName: "Transaction id",
            minWidth: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => {
                return "#5346848";
            },
        },
        {
            field: "date",
            headerName: "Date & Time",
            minWidth: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                        justifyContent: "center",
                        height: "100%",
                        flexDirection: "column",
                        px: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "100px",
                        }}
                    >
                        <img
                            src={calender}
                            alt="profile"
                            style={{ height: "21px", width: "21px" }}
                        />
                        <span style={{ fontSize: "14px" }}>01/01/2024</span>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "100px",
                        }}
                    >
                        <img
                            src={clock}
                            alt="profile"
                            style={{ height: "21px", width: "21px" }}
                        />
                        <span style={{ fontSize: "14px" }}>02:00 AM</span>
                    </Box>
                </div>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            minWidth: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => {
                return <Box sx={{ color: "#fd5d29", fontWeight: "700" }}>$200</Box>;
            },
        },
        {
            field: "status",
            headerName: "Payment Status ",
            minWidth: 200,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            display: "flex",
            align: "center",
            flex: 1,
            renderCell: (cell) => {
                const status = cell?.row?.status
                return <Box sx={{ color: "#159300", fontWeight: "500" }}>Active</Box>;
            },
        },

    ];

  const getData = async () => {
    try {
      setLoading(true);

      const res = await AXIOS.get(
        `${apiList.user.getAll}&arg=status != -1 and role NOT IN (3,4) &order=created_at desc&skip=0&limit=10&total=1`
      );
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        page === 0 && setTotalData(res?.total ?? 0);
        setData(serialData);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getData();
  }, []);
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const secondModule = accesslist?.find((module) => module.id === 2);
  console.log(secondModule, "secondmodule");

  const post_delete_access = !!secondModule?.access?.find(
    (access) => access.id === 14 && access.admin_user_id 
  );
  const post_details_access = !!secondModule?.access?.find(
    (access) => access.id === 15 && access.admin_user_id 
  );
  const payment_details_access = !!secondModule?.access?.find(
    (access) => access.id === 16 && access.admin_user_id 
  );
  return (
    <>
      <DataListingComponent
        data={data}
        PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
        loading={loading}
        totalData={totalData}
        page={page}
        columns={columns}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

export default UserPaymentHistory;
