import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ListDataScreenTab from "./ListDataScreenTab";
import AdvDataScreenTab from "./AdvDataScreenTab";
const ListManagementScreen = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Listing Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          height: "50px",
          display: { xs: "flex", lg: "none" },
          // border: "1px solid red",
        }}
      ></Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            pb: 1,
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            {[
              { id: 0, lable: "Listing" },
              {
                id: 1,
                lable: "Advertisement",
              },
            ]?.map((x, i) => (
              <Box
                sx={{
                  color: currentTab === x?.id ? "#fd5d29" : "#00000099",
                  textDecoration: currentTab === x?.id ? "underline" : "none",
                  cursor: "pointer",
                  fontWeight: 600
                }}
                onClick={() => setCurrentTab(x?.id)}
              >
                {x?.lable}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {currentTab === 0 ? <ListDataScreenTab /> : <AdvDataScreenTab />}
        </Box>
      </Box>
    </Box>
  );
};

export default ListManagementScreen;
