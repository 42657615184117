import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouteList } from "./RoutesList";
import DashboardScreen from "../Page/Dashboard/DashboardScreen";
import MainLayout from "../Page/MainLayout/Layout";
import ProtectedRoutes from "./ProtectedRoutes";
import UserScreen from "../Page/User/UserScreen";
import ContentScreen from "../Page/Content/ContentScreen";
import BusinessScreen from "../Page/Business/BusinessScreen";
import CategoryScreen from "../Page/Category/CategoryScreen";
import AuthScreen from "../Page/Auth/Auth";
import AdminRoleScreen from "../Page/AdminRole/AdminRoleScreen";
import UserDetailsScreen from "../Page/UserDetails/UserDetailsScreen";
import ListManagementScreen from "../Page/List(Post)/ListingManagement";
import HelpAndSupportScrren from "../Page/HelpAndSupport/HelpAndSupportScreen";
import PaymnetScreen from "../Page/Payment/PaymentScreen";
import ReviewScreen from "../Page/ReviewScreen/ReviewScreen";
import BusinessDetailsScreen from "../Page/BusinessDetails/BusinessDetails";
import MatricsScrren from "../Page/MatricsScreen/MatricsScreen";
import ListDetailsScreen from "../Page/ListDetailsScreen/ListDetailsScreen";
import NotificationManagementScreen from "../Page/Notification/NotificationScreen";
import ContentModeration from "../Page/ContentModeration/ContentModeration";
import ReportDetails from "../Page/ContentModeration/UserReportDetails";
import BusinessModeration from "../Page/BusinessModeration/BusinessModeration";
import ReportsAndLogsScreen from "../Page/Reports&Logs/Report&LogsScreen";
import ChatModeration from "../Page/Chat/ChatModeration";
import CommunityModeration from "../Page/Community/CommunityModeration";
import ChatManagement from "../Page/Chat/ChatManagement";
import { CommunityManagement } from "../Page/Community/CommunityManagement";
import FinanceManagement from "../Page/Finance/FinanceManagement";
import CommunityReportDetails from "../Page/CommunityReportDetails/CommunityReprtDetails";

const AllRoutes = () => {
  const routes = [
    {
      path: RouteList.dashboard,
      element: <DashboardScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.user,
      element: <UserScreen />,
      isPrivate: true,
    },

    {
      path: RouteList.business,
      element: <BusinessScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.businessModeration,
      element: <BusinessModeration />,
      isPrivate: true,
    },
    {
      path: RouteList.list,
      element: <ListManagementScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.category,
      element: <CategoryScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.content,
      element: <ContentScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.reportsAndLogs,
      element: <ReportsAndLogsScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.review,
      element: <ReviewScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.notification,
      element: <NotificationManagementScreen />,
      isPrivate: true,
    },

    {
      path: RouteList.adminRole,
      element: <AdminRoleScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.report,
      element: <ContentModeration />,
      isPrivate: true,
    },
    {
      path: RouteList.Subscription,
      element: <PaymnetScreen />,
      isPrivate: true,
    },

    {
      path: RouteList.matrics,
      element: <MatricsScrren />,
      isPrivate: true,
    },
    {
      path: RouteList.help,
      element: <HelpAndSupportScrren />,
      isPrivate: true,
    },

    {
      path: RouteList.ChatModeration,
      element: <ChatModeration />,
      isPrivate: true,
    },
    {
      path: RouteList.CommunityModeration,
      element: <CommunityModeration />,
      isPrivate: true,
    },
    {
      path: RouteList.ChatManagement,
      element: <ChatManagement />,
      isPrivate: true,
    },
    {
      path: RouteList.CommunityManagement,
      element: <CommunityManagement />,
      isPrivate: true,
    },
    {
      path: RouteList.financial,
      element: <FinanceManagement />,
      isPrivate: true,
    },
  ];

  const routesWithFullScreen = [
    {
      path: RouteList.login,
      element: <AuthScreen />,
      isPrivate: false,
    },
    {
      path: RouteList.userDetails,
      element: <UserDetailsScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.businessDetails,
      element: <BusinessDetailsScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.listDetails,
      element: <ListDetailsScreen />,
      isPrivate: true,
    },
    {
      path: RouteList.userreportdetails,
      element: <ReportDetails />,
      isPrivate: true,
    },
    {
      path: RouteList.ModerationDetails,
      element: <CommunityReportDetails />,
      isPrivate: true,
    },
  ];

  return (
    <Routes>
      {routes.map((route, i) => (
        <Route
          key={"screen" + i}
          path={route.path}
          element={
            <MainLayout
              subElement={<ProtectedRoutes component={route.element} />}
            />
          }
        />
      ))}
      {routesWithFullScreen.map((route, i) => (
        <Route
          key={"fullScreen-" + i}
          path={route.path}
          element={
            route.isPrivate ? (
              <ProtectedRoutes component={route.element} />
            ) : (
              route.element
            )
          }
        />
      ))}
    </Routes>
  );
};

export default AllRoutes;
