import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function BasicMenu({ rowData, options }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon style={{ color: "#fd5d29" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options &&
          options?.map((x, i) => (
            <MenuItem
              sx={{ py: "8px", display: x?.isActive ? "flex" : "none" }}
              disabled={x?.isDisabled}
              key={"menu" + i}
              onClick={() => x?.action(rowData)}
            >
              {x?.icon}&nbsp;{x?.title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
