
export const hasAccess = (parentId, childId = null) => {
  const accessList = JSON.parse(localStorage.getItem("Megalopolist-userData-roles")) || [];

  const parentModule =
    accessList?.find((module) => module.id == parentId) || {};

  if (!parentModule?.id) {
    return false; 
  }
  if (childId === null) {
    return true;
  }
  return !!parentModule?.access?.some(
    (acc) =>acc.id === childId  && acc.admin_user_id
  );
};
