import { Box, Button } from "@mui/material";
import { AXIOS } from "../../../Component/apiHelper";
import { apiList } from "../../../Component/apiList";
import { useState } from "react";
import { StatusList } from "../../../Component/StatusList";
import BasicMenu from "../../../Component/MenuOptioinComponent";
import { toast } from "react-toastify";
import DescriptionIcon from "@mui/icons-material/Description";
import BlockIcon from "@mui/icons-material/Block";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import DataListingComponent from "../../../Component/DataListGrid";
import DatePickerComponent from "../../../Component/DatePicker";

const ActiveLogsTab = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [open, setOpen] = useState(false);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    currentStatus: null,
  });

  const columns = [
    // {
    //   field: "rowid",
    //   headerName: "Sr.",
    //   width: 60,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   renderCell: (cell) => {
    //     return cell?.row?.rowid + page * rowsPerPage;
    //   },
    // },
    {
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      // renderCell: (cell) => (
      //     <div
      //         style={{
      //             display: "flex",
      //             justifyContent: "center",
      //             alignItems: "center",
      //             height: "100%",
      //         }}
      //         onClick={() => handleDetailsNav(cell?.row)}
      //     >
      //         <img
      //             src={
      //                 cell?.row?.profile_img
      //                     ? apiList.baseURL + cell?.row?.profile_img
      //                     : defaultUserImage
      //             }
      //             alt="profile"
      //             style={{ borderRadius: "12px", height: "35px", width: "35px" }}
      //         />
      //     </div>
      // ),
    },

    {
      field: "username",
      headerName: "User Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "phno",
      headerName: "Number",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.phno_cc && cell?.row?.phno
          ? cell?.row?.phno_cc + " " + cell?.row?.phno
          : "-";
      },
    },
    {
      field: "Account",
      headerName: "Account",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const { is_active } = cell?.row;
        const d =
          is_active === 0
            ? {
                lable: "Inactive",
                color: "grey",
              }
            : {
                lable: "Active",
              };
        return <span style={{ color: d?.color }}>{d?.lable}</span>;
      },
    },

    {
      field: "is_approve",
      headerName: "Type",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const g = cell?.row?.role;
        // const a = cell?.row?.is_approve === 1;

        return g === 2 ? (
          <Box
            sx={{
              color: "#FFF",
              border: "2px solid #FD5D29",
              width: "100px",
              borderRadius: "5px",
              height: "100%",
              background: "#FD5D29",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Business
          </Box>
        ) : (
          g === 1 && (
            <Box
              sx={{
                color: "#000",
                border: "2px solid #FD5D29",
                width: "100px",
                padding: 1,
                borderRadius: "5px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Individual
            </Box>
          )
        );
      },
    },

    {
      field: "AccountStatus",
      headerName: "Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const { status } = cell?.row;
        const lable = StatusList?.find((x) => x?.code == status);
        return <span style={{ color: lable?.color }}>{lable?.name}</span>;
      },
    },

    {
      field: "status",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const menuOptions = getMenuOptionList(cell?.row);
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <BasicMenu rowData={cell?.row} options={menuOptions} />
          </Box>
        );
      },
    },
  ];

  const getMenuOptionList = (data) => {
    const status = data?.status;
    const isPending = status === 0;
    const isVerified = status === 1;
    const isFlegged = status === -2;
    const isSuspended = status === -3;
    const isBanned = status === -4;
    return [
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isSuspended ? 1 : -3,
            currentStatus: status,
          });
        },
        title: isSuspended ? "Re-Active" : "Suspend",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isSuspended ? (
          <PersonIcon style={{ color: "#fd5d29" }} />
        ) : (
          <PersonOffIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isFlegged ? 1 : -2,
            currentStatus: status,
          });
        },
        title: isFlegged ? "Unflag" : "Flag",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isFlegged ? (
          <ReportOffIcon style={{ color: "#fd5d29" }} />
        ) : (
          <ReportGmailerrorredIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: !isBanned && -4,
            currentStatus: status,
          });
        },
        title: isBanned ? "Banned" : "Ban(permanent)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: <BlockIcon style={{ color: "#fd5d29" }} />,
      },
    ];
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    accountFilter,
    preAccountFilter,
    TypeFilter,
    preTypeFilterRef,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (
        filter !== prevFilter ||
        search !== prevSearch ||
        accountFilter !== preAccountFilter ||
        TypeFilter !== preTypeFilterRef
      ) {
        setPage(0);
        setData([]);
      }

      let endPoint = `?skip=${count}&limit=${PAGE_SIZE_OPTIONS}`;
      if (search?.length > 0) {
        endPoint += `&search=${search?.trim()}`;
      }
      if (filter !== 99 && filter !== 9) {
        endPoint += `&status=${filter}`;
      }
      if (accountFilter !== 99 && accountFilter !== 9) {
        endPoint += `&is_active=${accountFilter}`;
      }
      if (TypeFilter !== 99 && TypeFilter !== 9) {
        endPoint += `&role=${TypeFilter}`;
      }

      const res = await AXIOS.get(`${apiList.user.getAll}${endPoint}`);
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <DatePickerComponent
            name={"from_date"}
            value={from_date}
            setValue={setFromDate}
            placeholder={"From Date"}
          />
          <DatePickerComponent
            name={"to_date"}
            value={to_date}
            setValue={setToDate}
            placeholder={"To Date"}
          />
          <Button
            onClick={() => {
              setFromDate("");
              setToDate("");
            }}
            variant="contained"
            sx={{
              maxWidth: "100px",
              textTransform: "none",
              borderRadius: "12px",
              background: `grey`,
              display: from_date && to_date ? "flex" : "none",
            }}
          >
            Clear
          </Button>
        </Box>
      </Box> */}
      <Box sx={{ height: "calc(100vh - 170px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default ActiveLogsTab;
