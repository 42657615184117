import * as React from "react";
// import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
export default function ChatMenuOptions({
  dotVisiblity,
  msgId,
  handleDeleteMsg,
  isSender,
    handleDetailNav
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MoreVertIcon
        onClick={handleClick}
        sx={{
          height: "20px",
          cursor: "pointer",
          visibility: dotVisiblity === msgId ? "visible" : "hidden",
        }}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isSender ? (
          <MenuItem
            onClick={() => {
              handleDeleteMsg();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleDetailNav();
              handleClose();
            }}
          >
            User Detail
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
