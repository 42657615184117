import { useNavigate } from "react-router-dom";
import { RouteList } from "./RoutesList";
import { useState, useEffect } from "react";
import { AXIOS } from "./apiHelper";
import { apiList } from "./apiList";

const ProtectedRoutes = ({ component }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getAccessList = async (userId) => {
    try {
      let res = await AXIOS.get(apiList?.subAdmin?.accessbyuserID, {
        params: { user_id: userId },
      });
      if (res?.r) {
        localStorage.setItem(
          "Megalopolist-userData-roles",
          JSON.stringify(res?.r)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("Megalopolist-userData"));
    if (user && user.status === 1) {
      setIsAuthenticated(true);
      getAccessList(user?.id);
    } else {
      localStorage.clear();
      navigate(RouteList.login);
    }
    setLoading(false);
  }, [navigate]);

  if (loading) {
    return null;
  }

  return isAuthenticated ? component : null;
};

export default ProtectedRoutes;
