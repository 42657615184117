import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function DetailsActionButton({ rowData, options }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // variant="contained"
        onClick={handleClick}
        sx={{
          width: "100%",
          padding: "5px 18px",
          maxWidth: "300px",
          textTransform: "none",
          borderRadius: "10px",
          border: "1px solid #fd5d29",
          color: "#fd5d29",
          // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
          background: `#Fd5D2924`,
        }}
      >
        Action{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options &&
          options?.map((x, i) => (
            <MenuItem
              sx={{ py: "8px" }}
              disabled={x?.isDisabled}
              key={"menu" + i}
              onClick={() => {
                x?.action(rowData);
                handleClose();
              }}
            >
              {x?.icon}&nbsp;{x?.title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
