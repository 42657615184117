import { Box, Button, Tooltip, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import child from "../../Assets/Image/child.png";
import defaultImage from "../../Assets/Image/logo.webp";
import edit from "../../Assets/Image/edit.webp";
import deleteCat from "../../Assets/Image/delete.webp";
import CategoryModal from "./CategoryManageModal";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import logo from "../../Assets/Image/question.webp";

const SUbCategoryScreen = ({ id, label, setSubCategoriesData, index }) => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  // const [filter, setFilter] = useState("status != -1 and role != 3");
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState(0);
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const third = accesslist?.find((module) => module.id === 14);
  const add_access = !!third?.access?.find(
    (access) => access.id === 23 && access.admin_user_id
  );
  const edit_access = !!third?.access?.find(
    (access) => access.id === 24 && access.admin_user_id
  );
  const delete_access = !!third?.access?.find(
    (access) => access.id === 25 && access.admin_user_id
  );

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.rowid + page * rowsPerPage}
        </div>
      ),
    },

    {
      field: "category_name",
      headerName: "Sub Category ",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      display: "flex",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title={params.value || ""} arrow>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (delete_access || edit_access)
  {
    const actionColumn = {
      field: "status",
      headerName: "Action",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
              <img
                src={edit}
                alt="update"
                style={{
                  height: "25px",
                  width: "25px",
                  display: edit_access ? "block" : "none",
                }}
                onClick={() => {
                  setModalData(cell?.row);
                  setModalType("update-sub");
                }}
              />
              <img
                src={deleteCat}
                style={{
                  height: "25px",
                  width: "25px",
                  display: delete_access ? "block" : "none",
                }}
                alt="View"
                onClick={() => {
                  setOpen(true);
                  setActionParams(cell?.row?.id);
                }}
              />
              <img
                src={child}
                style={{
                  height: "25px",
                  width: "25px",
                  display: index < 3 ? "block" : "none",
                }}
                alt="child"
                onClick={() => {
                  // setLevel(index + 2);
                  setSubCategoriesData((prev) => {
                    let newData = [...prev];
                    const existingIndex = newData.findIndex(
                      (item) => item.level == index + 2
                    );
                    if (existingIndex !== -1)
                    {
                      newData[existingIndex] = {
                        id: cell?.row?.id,
                        label: cell?.row?.category_name,
                        level: index + 2,
                      };
                    } else
                    {
                      newData.push({
                        id: cell?.row?.id,
                        label: cell?.row?.category_name,
                        level: index + 2,
                      });
                    }

                    if (newData.length > index + 2)
                    {
                      newData = newData.slice(0, index + 2);
                    }

                    return newData;
                  });
                }}
              />
            </Box>
          </Box>
        );
      },
    };
    columns.push(actionColumn);
  }

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    search,
    prevSearch,
    id,
    prevId
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      if (id !== prevId || search !== prevSearch)
      {
        setData([]);
        setPage(0);
      }

      let searchParams =
        search?.length > 0
          ? `parent_id=${id}&search=${search?.trim()}`
          : `parent_id=${id}`;
      const res = await AXIOS.get(
        `${apiList.category.getAll}?${searchParams}&skip=${count}&limit=${rowsPerPage}`
      );
      if (res?.s)
      {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteCategory = async (childId) => {
    try
    {
      setLoading(true);
      const res = await AXIOS.post(`${apiList.category.deleteSubCat}`, {
        id: childId,
      });
      if (res?.s)
      {
        // setLevel(index + 2);
        setSubCategoriesData((prev) => {
          let newData = [...prev];
          if (newData.length > index + 1)
          {
            newData = newData.slice(0, index + 1);
          }

          return newData;
        });
        toast.error("Deleted successfully");
        await getData(
          page,
          rowsPerPage,
          debouncedSearchTerm,
          searchRef.current,
          id,
          parentIdRef.current
        );
        setLoading(false);
      } else
      {
        toast.error(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      console.log(error);
      setLoading(false);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const parentIdRef = useRef(id);

  useEffect(() => {
    id !== 0 &&
      getData(
        page,
        rowsPerPage,
        debouncedSearchTerm,
        searchRef.current,
        id,
        parentIdRef.current
      );
    searchRef.current = debouncedSearchTerm;
    parentIdRef.current = id;
  }, [debouncedSearchTerm, id, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            <h4>
              {`${label
                ? `Sub Category of ${label?.length > 18 ? label?.slice(0, 18) + "..." : label
                }`
                : "Sub Category"
                }`}
            </h4>
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{}}>
              <Button
                sx={{
                  display: add_access ? "block" : "none",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "15px",
                  fontSize: "17px",
                  color: "#FFF",
                  background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  "&:hover": {
                    background: "#00075",
                  },
                  padding: "8px",
                }}
                disabled={id === 0 ? true : false}
                onClick={() => {
                  setModalData("0");
                  setModalType("add-sub");
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search by name"
            disabled={id === 0 ? true : false}
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        <Box sx={{ height: "calc(100dvh - 270px)" }}>
          <DataListingComponent
            data={data}
            PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
            loading={loading}
            totalData={totalData}
            page={page}
            columns={columns}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          // rowHeight="auto"
          />
        </Box>
      </Box>
      <CategoryModal
        data={modalData}
        open={modalData}
        setOpen={setModalData}
        type={modalType}
        parentId={id}
        lable={label}
        getData={() =>
          getData(
            page,
            rowsPerPage,
            debouncedSearchTerm,
            searchRef.current,
            id,
            parentIdRef.current
          )
        }
      />
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        logo={logo}
        onConfirm={() => handleDeleteCategory(actionParams)}
        title="Confirm Action"
        message="Are you sure want to delete sub-category?"
        confirmText="Confirm"
        cancelText="Cancel"
        loading={loading}
      />
    </Box>
  );
};

export default SUbCategoryScreen;
