import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";

const UploadModal = ({ open, handleClose, handleDataSubmit, getData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("bulk_file", selectedFile);
      setLoading(true);
      try {
        const response = await AXIOS.post(apiList?.category?.addbulk, formData);
        if (response.s === 1) {
          toast.success("File uploaded successfully!");
          getData();
          handleDataSubmit(response.data);
          setLoading(false);
        } else {
          console.error("Upload failed:", response.statusText);
          toast.error("File upload failed. Please try again.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("An error occurred while uploading the file.");
        setLoading(false);
      }

      handleClose();
    } else {
      toast.warn("Please upload an Excel file first.");
      setLoading(false);
    }
  };

  // Reset selectedFile when modal closes
  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6">Upload Excel File</Typography>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{ display: "none" }}
          id="file-input"
        />
        <label htmlFor="file-input">
          <IconButton
            component="span"
            sx={{
              color: "#FD5D29",
              backgroundColor: "#FFF6F3",
              border: "1px solid #FD5D29",
              borderRadius: "12px",
              padding: "13px",
              width: "100%",
            }}
          >
            <CloudUpload fontSize="large" />
          </IconButton>
          <Box className="flex-center">
            <Typography
              sx={{ color: "grey", fontSize: "14px", textAlign: "center" }}
            >
              {selectedFile ? selectedFile.name : "Upload File (xls)"}
            </Typography>
          </Box>
        </label>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
            sx={{
              mt: 1,
              width: "100%",
              textTransform: "none",
              borderRadius: "15px",
              fontSize: "17px",
              color: "#FFF",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              "&:hover": { background: "#00075" },
              padding: "8px",
            }}
          >
            {loading ? <CircularProgress /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default UploadModal;
