import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Send, ArrowBack, Refresh } from "@mui/icons-material";
import ChatHeaderActionButton from "./ChatHeaderActionButton";
import moment from "moment";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import ChatMenuOptions from "../../Component/ChatMenuOptions";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

const ChatDetails = ({ onBack, data }) => {
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [dotVisiblity, setDotVisiblity] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.5 });
  const idRef = useRef(data?.id);
  idRef.current = data?.id;
  const chatEndRef = useRef(null);
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);
  const IsFirst = data?.user_one_details?.id;

  const fetchData = async (id, count) => {
    if (fetchingMore) return;
    try {
      count === 0 && setLoading(true);
      setFetchingMore(true);
      let res = await AXIOS.get(apiList.chatManagement.getMessages, {
        params: { chat_id: id, skip: count ?? 0, limit: 30 },
      });
      if (res?.s) {
        setChatData((prev) => (count === 0 ? res?.r : [...prev, ...res?.r]));
        count === 0 && setTotalData(res?.total);
      } else {
        toast.warn(res?.m);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFetchingMore(false);
    }
  };

  const handleDeleteMsg = async (chat_id, msg_id) => {
    try {
      let f = new FormData();
      f.append("chat_id", chat_id);
      f.append("message_id", msg_id);
      f.append("is_delete", 1);
      let res = await AXIOS.post(apiList.chat.deleteMsg, f);
      if (res?.s) {
        setChatData((prev) => {
          if (prev?.id === msg_id) {
            return { ...prev, is_delete: 1 };
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(data?.id, 0);
  }, [data?.id]);

  useEffect(() => {
    if (inView && chatData.length < totalData) {
      fetchData(data?.id, chatData.length);
    }
  }, [inView]);

  const byDate = chatData.reduce((obj, item) => {
    const messageDate = moment(item?.created_at);
    let displayDate = messageDate.isSame(moment(), "day")
      ? "Today"
      : messageDate.isSame(moment().subtract(1, "days"), "day")
      ? "Yesterday"
      : messageDate.format("ll");
    obj[displayDate] = obj[displayDate] ? [...obj[displayDate], item] : [item];
    return obj;
  }, {});



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 95px)",
        bgcolor: "#FD5D290F",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          bgcolor: "#FD5D290F",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          borderRadius: "16px 16px 0 0",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>
          <AvatarGroup max={2} sx={{ mx: 2 }}>
            {[data?.user_one_details, data?.user_two_details].map(
              (user, index) => (
                <Avatar
                  key={index}
                  src={
                    user?.profile_img ? apiList.baseURL + user.profile_img : ""
                  }
                  alt={user?.username}
                  sx={{ width: 40, height: 40 }}
                />
              )
            )}
          </AvatarGroup>
          <Typography fontWeight={600}>
            {data?.user_one_details?.username},{" "}
            {data?.user_two_details?.username}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <IconButton style={{ background: "#fd5d29" }}>
            <Refresh
              style={{ color: "#FFF" }}
              onClick={() => fetchData(data?.id, 0)}
            />
          </IconButton>
          {/* <ChatHeaderActionButton /> */}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          p: 2,
          display: "flex",
          flexDirection: "column-reverse",
          position: "relative",
          height: "calc(100vh - 200px)",
        }}
      >
        {loading ? (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <CircularProgress style={{ color: "#fd5d29" }} />
          </Box>
        ) : Object.entries(byDate).length > 0 ? (
          Object.entries(byDate).map(([date, msgs]) => (
            <React.Fragment key={date}>
              {msgs?.map((msg) => {
                const isSender = msg?.receiver_id === IsFirst;
                const profileImg = isSender
                  ? data?.user_one_details?.profile_img
                  : data?.user_two_details?.profile_img;

                return (
                  <React.Fragment key={msg.id}>
                    {/* Time Box */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignSelf: isSender ? "flex-end" : "flex-start",
                        maxWidth: "75%",
                        mb: 1,
                      }}
                    >
                      {!isSender && <Box sx={{ width: 24, height: 24 }} />}
                      <Typography
                        variant="caption"
                        sx={{
                          opacity: 0.6,
                          fontSize: "12px",
                          textAlign: isSender ? "right" : "left",
                        }}
                      >
                        {isSender && msg?.is_delete === 1 && (
                          <span
                            style={{
                              fontSize: "14px",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            Deleted
                          </span>
                        )}

                        {moment(msg.created_at).format("LT")}
                        {!isSender && msg?.is_delete === 1 && (
                          <span
                            style={{
                              fontSize: "14px",
                              color: "red",
                              margin: "10px",
                            }}
                          >
                            Deleted
                          </span>
                        )}
                      </Typography>
                      {<Box sx={{ width: 24, height: 24 }} />}
                    </Box>

                    {/* Message Box */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        justifyContent: isSender ? "flex-end" : "flex-start",
                      }}
                      onMouseEnter={() => setDotVisiblity(msg?.id)}
                      onMouseLeave={() => setDotVisiblity(null)}
                    >
                      {!isSender && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "end",
                            height: "100%",
                            // border: '1px solid red'
                          }}
                        >
                          <Avatar
                            src={profileImg && `${apiList.baseURL}${profileImg}`}
                            sx={{ width: 20, height: 20 }}
                          />
                        </Box>
                      )}
                      <Box
                        sx={{
                          bgcolor: isSender
                            ? msg?.is_delete === 1
                              ? "#000"
                              : "#FD5D29"
                            : msg?.is_delete === 1
                            ? "#000"
                            : "#fff",
                          color: isSender
                            ? "#fff"
                            : msg?.is_delete === 1
                            ? "#FFF"
                            : "#333",
                          p: 1.5,
                          borderRadius: isSender
                            ? "15px 15px 0px 15px"
                            : "15px 15px 15px 0",
                          maxWidth: "75%",
                          boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                          ml: isSender ? "auto" : 0,
                          display: msg?.message_type === 1 ? "flex" : "none",
                        }}
                      >
                        <Typography variant="body2" sx={{ fontSize: "18px" }}>
                          {msg?.message}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          bgcolor: isSender ? "#FD5D29" : "#fff",
                          color: isSender ? "#fff" : "#333",
                          p: 1.5,
                          borderRadius: isSender
                            ? "15px 15px 0px 15px"
                            : "15px 15px 15px 0",
                          maxWidth: "75%",
                          boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                          ml: isSender ? "auto" : 0,
                          display: msg?.message_type === 2 ? "flex" : "none",
                        }}
                      >
                        <img
                          src={msg?.media && apiList.baseURL + msg?.media}
                          width={220}
                          alt="upload"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              `${apiList.baseURL + msg?.media}`,
                              "_blank"
                            )
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          bgcolor: isSender ? "#FD5D29" : "#fff",
                          color: isSender ? "#fff" : "#333",
                          p: 1.5,
                          borderRadius: isSender
                            ? "15px 15px 0px 15px"
                            : "15px 15px 15px 0",
                          maxWidth: "75%",
                          boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                          ml: isSender ? "auto" : 0,
                          display: msg?.message_type === 3 ? "flex" : "none",
                          position: "relative",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={msg?.thumb && apiList.baseURL + msg?.thumb}
                          width={220}
                          alt="upload"
                          style={{ cursor: "pointer", borderRadius: "9px" }}
                          onClick={() =>
                            window.open(
                              `${apiList.baseURL + msg?.media}`,
                              "_blank"
                            )
                          }
                        />
                        <PlayCircleFilledIcon
                          style={{
                            cursor: "pointer",
                            color: "#fff",
                            fontSize: "36px",
                            position: "absolute",
                          }}
                        />
                      </Box>

                      {isSender && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "end",
                            height: "100%",
                          }}
                        >
                          {/* <ChatMenuOptions
                            msgId={msg.id}
                            dotVisiblity={dotVisiblity}
                            handleDeleteMsg={() =>
                              handleDeleteMsg(msg?.chat_id, msg?.id)
                            }
                          /> */}
                          <Avatar
                            src={profileImg && `${apiList.baseURL}${profileImg}`}
                            sx={{ width: 20, height: 20 }}
                          />
                        </Box>
                      )}
                    </Box>
                  </React.Fragment>
                );
              })}

              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 111,
                  textAlign: "center",
                  my: 1,
                }}
              >
                <span
                  style={{
                    background: "#F3F2F2",
                    padding: "5px 10px",
                    borderRadius: "8px",
                  }}
                >
                  {date}
                </span>
              </Box>
            </React.Fragment>
          ))
        ) : (
          <Box className="flexCenter">
            <span
              style={{
                background: "#F3F2F2",
                padding: "5px 10px",
                borderRadius: "8px",
                color: "#fd5d29",
              }}
            >
              No chat found
            </span>
          </Box>
        )}
        <div
          ref={ref}
          style={{
            textAlign: "center",
            visibility: fetchingMore && !loading ? "visible" : "hidden",
          }}
        >
          {fetchingMore && <CircularProgress style={{ color: "#fd5d2955" }} />}
        </div>
      </Box>
    </Box>
  );
};

export default ChatDetails;
