import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function AdminRoleModal({
  open,
  setOpen,
  singleDetails,
  typeCheck,
  getData,
  setSingleUserDetails,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [subRightsList, setSubRightList] = useState([]);
  const [checkedRights, setCheckedRights] = useState({});

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      Username: "",
      module_ids: "",
      access_right_ids: "",
    },
    validationSchema: yup.lazy((values) => {
      if (typeCheck === "Edit")
      {
        return yup.object({
          email: yup.string().matches(emailRegex, "Invalid email"), // Optional
          password: yup
            .string()
            .min(6, "Required at least 6 digits.")
            .max(20, "Maximum 20 digits allowed"), // Optional
          Username: yup.string(), // Optional
        });
      }
      return yup.object({
        email: yup
          .string()
          .matches(emailRegex, "Invalid email")
          .required("Required"),
        password: yup
          .string()
          .required("Required")
          .min(6, "Required at least 6 digits.")
          .max(20, "Maximum 20 digits allowed"),
        Username: yup.string().required("Required"),
      });
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try
      {
        setSubmitting(true);
        setloading(true);

        const formData = new FormData();
        formData.append("email", values?.email);
        formData.append("password", values?.password);
        formData.append("username", values?.Username);

        let res;
        if (typeCheck === "Edit")
        {
          if (singleDetails?.id)
          {
            const formData1 = new FormData();
            formData1.append("user_id", singleDetails?.id);

            // Merge the new module_ids with the existing ones
            const existingModuleIds =
              singleDetails?.module_ids?.split(",") || [];
            const newModuleIds = values?.module_ids?.split(",");
            const mergedModuleIds = Array.from(
              new Set([...existingModuleIds, ...newModuleIds])
            );
            formData1.append("module_ids", mergedModuleIds.join(",") || 0);

            // Merge the new access_right_ids with the existing ones
            const existingAccessRightIds =
              singleDetails?.access_right_ids?.split(",") || [];
            const newAccessRightIds = values?.access_right_ids?.split(",");
            const mergedAccessRightIds = Array.from(
              new Set([...existingAccessRightIds, ...newAccessRightIds])
            );
            formData1.append(
              "access_rights_ids",
              mergedAccessRightIds.join(",") || 0
            );

            const res1 = await AXIOS.post(
              apiList.subAdmin.add_update_access_right,
              formData1
            );
            if (res1?.s)
            {
              toast.success("Successfully saved changes.");
            } else
            {
              toast.error(res1?.m || "Failed to update access rights");
            }
          }
        } else
        {
          res = await AXIOS.post(apiList.subAdmin.add, formData);
        }

        if (res?.s)
        {
          const userId = typeCheck === "Edit" ? singleDetails?.id : res?.r?.id;
          if (userId)
          {
            const formData1 = new FormData();
            formData1.append("user_id", userId);
            formData1.append("module_ids", values?.module_ids);
            formData1.append("access_rights_ids", values?.access_right_ids);

            const res1 = await AXIOS.post(
              apiList.subAdmin.add_update_access_right,
              formData1
            );
            if (res1?.s)
            {
              toast.success("Successfully saved changes.");
            } else
            {
              toast.error(res1?.m || "Failed to update access rights");
            }
          }
        }

        setloading(false);
        setOpen(false);
        getData();
        setSubmitting(false);
      } catch (error)
      {
        console.error(error);
        setloading(false);
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (typeCheck === "Edit")
    {
      formik.setFieldValue("email", singleDetails?.email);
      formik.setFieldValue("Username", singleDetails?.username);
      formik.setFieldValue("password", "");
      getAccessList(singleDetails?.id);
    } else
    {
      formik.resetForm();
      getAccessList(0);
    }
  }, [typeCheck, singleDetails]);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClose = () => {
    setOpen("");
    setSingleUserDetails({});
    setSingleUserDetails({});
    formik.resetForm();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getAccessList = async (userId) => {
    try
    {
      setloading(true);
      let res = await AXIOS.get(apiList?.subAdmin?.accessbyuserID, {
        params: { user_id: userId },
      });

      if (res?.r)
      {
        setSubRightList(res.r);

        const initialCheckedRights = {};
        const moduleIds = [];
        const accessRightIds = [];

        res.r.forEach((module) => {
          if (module?.admin_user_id !== null)
          {
            // Only add to Formik values if admin_user_id is not null
            moduleIds.push(module.id); // Collect module IDs

            initialCheckedRights[`right-${module?.id}`] = true;

            module?.access?.forEach((accessRight) => {
              if (accessRight?.admin_user_id !== null)
              {
                // Only add access right if admin_user_id is not null
                accessRightIds.push(accessRight.id); // Collect access right IDs
                initialCheckedRights[`access-${accessRight.id}`] = true;
              }
            });
          }
        });

        // Set Formik field values only when admin_user_id is not null
        formik.setFieldValue("module_ids", moduleIds.join(","));
        formik.setFieldValue("access_right_ids", accessRightIds.join(","));

        setCheckedRights(initialCheckedRights);
        setloading(false);
      }
    } catch (error)
    {
      console.log(error);
      setloading(false);
    }
  };
  const handleCheckboxChange = (id) => (event) => {
    const isChecked = event.target.checked;
    const [type, itemId] = id?.split("-");

    setCheckedRights((prev) => ({ ...prev, [id]: isChecked }));

    if (type === "right")
    {
      let moduleIds = formik.values.module_ids?.split(",") || [];
      if (isChecked)
      {
        moduleIds.push(itemId);

        const relatedRight = subRightsList.find((right) => right.id === Number(itemId));
        if (relatedRight)
        {
          relatedRight.access.forEach((accessRight) => {
            setCheckedRights((prev) => ({
              ...prev,
              [`access-${accessRight.id}`]: true,
            }));

            formik.setFieldValue(
              "access_right_ids",
              [
                ...(formik.values.access_right_ids?.split(",") || []),
                `${accessRight.id}`,
              ]
                .filter(Boolean)
                .join(",")
            );
          });
        }
      } else
      {
        moduleIds = moduleIds.filter((item) => item !== itemId);
        const relatedRight = subRightsList.find((right) => right.id === Number(itemId));
        if (relatedRight)
        {
          relatedRight.access.forEach((accessRight) => {
            setCheckedRights((prev) => ({
              ...prev,
              [`access-${accessRight.id}`]: false,
            }));
            formik.setFieldValue(
              "access_right_ids",
              formik.values.access_right_ids
                ?.split(",")
                .filter((item) => item !== `${accessRight.id}`)
                .join(",")
            );
          });
        }
      }

      formik.setFieldValue("module_ids", moduleIds.filter(Boolean).join(","));
    } else
    {
      let accessRightIds = formik.values.access_right_ids?.split(",") || [];
      if (isChecked)
      {
        accessRightIds.push(itemId);
        const relatedRight = subRightsList.find((right) =>
          right.access.some((accessRight) => accessRight.id === Number(itemId))
        );
        if (relatedRight)
        {
          let moduleIds = formik.values.module_ids?.split(",") || [];
          if (!moduleIds.includes(`${relatedRight.id}`))
          {
            moduleIds.push(`${relatedRight.id}`);
          }
          formik.setFieldValue("module_ids", moduleIds.filter(Boolean).join(","));
          setCheckedRights((prev) => ({
            ...prev,
            [`right-${relatedRight.id}`]: true,
          }));
        }
      } else
      {
        accessRightIds = accessRightIds.filter((item) => item !== itemId);
      }
      formik.setFieldValue(
        "access_right_ids",
        accessRightIds.filter(Boolean).join(",")
      );
    }
  };


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            overflow: "auto",
            maxHeight: "calc(100vh - 100px)",
            maxWidth: { xs: "calc(100% - 50px)", md: "50%" },
            width: "100%",

          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: "600" }}
          >
            {typeCheck === "Edit" ? "Edit Sub-Admin" : "Add Sub-admin"}
          </Typography>
          <Box className="flexCenter">
            <Box sx={{ width: { md: "550px" } }}>
              <Box>
                <label
                  htmlFor="email"
                  style={{ fontSize: "14px", paddingLeft: "0px" }}
                >
                  Email
                </label>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  disabled={typeCheck === "Edit"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  variant="outlined"
                  fullWidth
                  focused
                  sx={{
                    margin: "0.25rem 0 0 0",
                    ".MuiInputBase-root": {
                      borderRadius: "15px",
                      background: "#FD5D2914",
                      color: "#000",
                      border: "1px solid #FD5D2966",
                    },
                    ".MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    ".MuiInputBase-root:hover": {
                      background: "#FF8862",
                      color: "#fff",
                    },
                    "& input[type='password']::-ms-reveal": {
                      display: "none",
                    },
                    "& input[type='password']::-ms-clear": {
                      display: "none",
                    },

                    ".MuiOutlinedInput-input:focus": {
                      outline: "none",
                    },
                    fieldset: {
                      border: "none",
                    },
                  }}
                  placeholder="Enter your email"
                />
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  px: 1.5,
                  letterSpacing: "0.03rem",
                  py: 0.25,
                  height: "15px",
                  color: "#d32f2f",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                {formik.touched.email &&
                  Boolean(formik.errors.email) &&
                  formik.errors.email}
              </Box>

              <Box sx={{ display: typeCheck === "Edit" ? "none" : "block" }}>
                <label
                  htmlFor="password"
                  style={{ fontSize: "14px", paddingLeft: "0px" }}
                >
                  Password
                </label>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "15px",
                      background: "#FD5D2914",
                      color: "#000",
                      border: "1px solid #FD5D2966",
                    },
                    ".MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      background: "#FF8862",
                      color: "#fff",
                    },
                    "& .MuiOutlinedInput-input:focus": {
                      outline: "none",
                    },
                    fieldset: {
                      border: "none",
                    },
                  }}
                >
                  <OutlinedInput
                    sx={{
                      "& input[type='password']::-ms-reveal": {
                        display: "none",
                      },
                      "& input[type='password']::-ms-clear": {
                        display: "none",
                      },
                    }}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                color: "#FF602D",
                              }}
                            />
                          ) : (
                            <Visibility style={{ color: "#FF602D" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    id="password"
                    name="password"
                    disabled={typeCheck === "Edit"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onKeyDown={(key) =>
                      key.keyCode === 13 && formik.handleSubmit()
                    }
                    placeholder="Password"
                  />
                  <Box
                    sx={{
                      fontSize: "12px",
                      px: 1.5,
                      letterSpacing: "0.03rem",
                      py: 0.25,
                      height: "15px",
                      color: "#d32f2f",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {formik.touched.password &&
                      Boolean(formik.errors.password) &&
                      formik.errors.password}
                  </Box>
                </FormControl>
              </Box>

              <Box>
                <label
                  htmlFor="Username"
                  style={{ fontSize: "14px", paddingLeft: "0px" }}
                >
                  User Name
                </label>
                <TextField
                  type="text"
                  id="Username"
                  name="Username"
                  disabled={typeCheck === "Edit"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Username}
                  error={
                    formik.touched.Username && Boolean(formik.errors.Username)
                  }
                  variant="outlined"
                  fullWidth
                  focused
                  sx={{
                    margin: "0.25rem 0 0 0",
                    ".MuiInputBase-root": {
                      borderRadius: "15px",
                      background: "#FD5D2914",
                      color: "#000",
                      border: "1px solid #FD5D2966",
                    },
                    ".MuiOutlinedInput-input": {
                      padding: "14px",
                    },
                    ".MuiInputBase-root:hover": {
                      background: "#FF8862",
                      color: "#fff",
                    },
                    "& input[type='password']::-ms-reveal": {
                      display: "none",
                    },
                    "& input[type='password']::-ms-clear": {
                      display: "none",
                    },

                    ".MuiOutlinedInput-input:focus": {
                      outline: "none",
                    },
                    fieldset: {
                      border: "none",
                    },
                  }}
                  placeholder="User Name"
                />
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  px: 1.5,
                  letterSpacing: "0.03rem",
                  py: 0.25,
                  height: "15px",
                  color: "#d32f2f",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                {formik.touched.Username &&
                  Boolean(formik.errors.Username) &&
                  formik.errors.Username}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              width: "100%",
            }}
          >
            <label
              htmlFor="checkb"
              style={{ fontSize: "14px", paddingLeft: "0px", fontWeight: '600' }}
            >
              Modules
            </label>
            {subRightsList.map((right) => (
              <Box key={`right-${right.id}`} sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#fd5d29" }}
                      name={`right-${right.id}`}
                      checked={!!checkedRights[`right-${right.id}`]} // Use !! to ensure boolean value
                      onChange={handleCheckboxChange(`right-${right.id}`)}
                    />
                  }
                  sx={{ color: "#737373" }}
                  label={right.name}
                />
                {right.access &&
                  right.access.map((accessRight) => (
                    <Box key={`access-${accessRight.id}`} sx={{ pl: 4, mt: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#fd5d29" }}
                            name={`access-${accessRight.id}`}
                            checked={
                              !!checkedRights[`access-${accessRight.id}`]
                            } // Same logic for access rights
                            onChange={handleCheckboxChange(
                              `access-${accessRight.id}`
                            )}
                          />
                        }
                        sx={{ color: "#737373" }}
                        label={accessRight.name}
                      />
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              sx={{
                width: "100%",
                maxWidth: "330px",
                textTransform: "none",
                borderRadius: "15px",
                fontSize: "17px",
                background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                "&:hover": {
                  background: "#00075",
                },
                padding: "12px",
              }}
            >
              {typeCheck === "Edit"
                ? formik.isSubmitting
                  ? "Updating..."
                  : "Update"
                : formik.isSubmitting
                  ? "Submitting..."
                  : "Submit"}
              &nbsp;
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
