import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { Send, ArrowBack, Refresh } from "@mui/icons-material";
// import ChatHeaderActionButton from "./ChatHeaderActionButton";
import moment from "moment";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import logo from "../../Assets/Image/comminityChatLogo.webp";
import io from "socket.io-client";
import ChatMenuOptions from "../../Component/ChatMenuOptions";
import AddIcon from "@mui/icons-material/Add";

import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";

const socket = io(`${apiList.baseURL + apiList.chat.socket}?user_id=1`, {
  transports: ["websocket"],
  withCredentials: true,
});

export const CommunityManagement = () => {
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState({});
  const [totalData, setTotalData] = useState(0);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [dotVisiblity, setDotVisiblity] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { ref, inView } = useInView({ threshold: 0.5 });
  const navigate = useNavigate();
  const chatEndRef = useRef(null);

  socket.on("connect", () => {
    console.log("Connected to WebSocket server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from WebSocket server");
  });

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  socket?.on("connect_error", (err) => {
    console.log(err.message);
    console.log(err.description);
    console.log(err.context);
    console.log(err);
  });

  useEffect(() => {
    getList(0);
  }, []);

  const getList = async (count) => {
    const userData = JSON.parse(localStorage.getItem("Megalopolist-userData"));
    try
    {
      let response = await AXIOS.get(apiList.chat.getGroups, {
        params: {
          skip: count,
          limit: 30,
        },
      });
      if (response?.s)
      {
        setListData(response?.r[0]);
        let res = await AXIOS.post(apiList.community.joinCommunity, {
          chat_id: response?.r[0]?.id,
          user_id: userData?.id,
        });
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!socket) return;

    const handleIncomingMessage = (data) => {
      try
      {
        const userData = JSON.parse(
          localStorage.getItem("Megalopolist-userData")
        );

        let updatedUserDetails;
        try
        {
          updatedUserDetails = JSON.parse(data?.user_details || "{}");
        } catch (err)
        {
          console.error("Failed to parse user_details", err);
          return;
        }
        data.user_details = updatedUserDetails;

        if (data?.user_details?.id == userData?.id) return;

        setChatData((prevMessages) => {
          if (data?.is_delete == 1)
          {
            return prevMessages.map((msg) =>
              msg.id === data.id ? { ...msg, is_delete: 1 } : msg
            );
          }
          // if (!prevMessages.some((msg) => msg.id == data.id)) {
          return [data, ...prevMessages];
          // }
          // return prevMessages;
        });
      } catch (error)
      {
        console.error("Error in handleIncomingMessage:", error);
      }
    };

    socket.on("get-msg", handleIncomingMessage);

    return () => {
      socket.off("get-msg", handleIncomingMessage);
    };
  }, [socket]);

  const fetchData = async (id, count) => {
    if (fetchingMore) return;
    try
    {
      count === 0 && setLoading(true);
      setFetchingMore(true);
      let res = await AXIOS.get(apiList.chat.getMessages, {
        params: { chat_id: id, skip: count ?? 0, limit: 30 },
      });
      if (res?.s)
      {
        setChatData((prev) => (count === 0 ? res?.r : [...prev, ...res?.r]));
        count === 0 && setTotalData(res?.total);
      } else
      {
        toast.warn(res?.m);
      }
    } catch (error)
    {
      console.error(error);
    } finally
    {
      setLoading(false);
      setFetchingMore(false);
    }
  };

  const updateDBMessage = async (data) => {
    try
    {
      let res = await AXIOS.post(apiList.chat.sendMsg, data);

      if (res?.s == 0)
      {
        toast.warn(res?.m || "Message failed to send");
        return null;
      }

      return res?.r;
    } catch (error)
    {
      console.error("Error sending message:", error);
      return null;
    }
  };

  const handleSendMessage = async (type) => {
    try
    {
      if (type == 1)
      {
        if (!listData?.id || inputMessage.trim() === "") return;
      } else if (type == 2 || type == 3)
      {
        if (!listData?.id || !selectedFile?.type) return;
      }

      let f = new FormData();
      f.append("chat_id", listData?.id);
      f.append("message_type", type ?? 1);
      f.append("type", type ?? 1);
      if (type == 1)
      {
        f.append("message", inputMessage);
      }
      if (type == 2)
      {
        f.append("media", selectedFile);
      }
      if (type == 3)
      {
        const thumbnail = await generateThumbnail(selectedFile);
        const blob = await fetch(thumbnail).then((res) => res.blob());
        f.append("media", selectedFile);
        f.append("thumb", blob, "thumbnail.jpg");
      }
      setInputMessage("");
      setSelectedFile({});

      const savedMessage = await updateDBMessage(f);

      if (savedMessage)
      {
        const userData = JSON.parse(
          localStorage.getItem("Megalopolist-userData")
        );

        let user_data = {
          id: userData?.id,
          username: userData?.username,
          profile_img: userData?.profile_img ?? null,
        };
        let stringifiedUserData = JSON.stringify(user_data);
        let updatedData = {
          ...savedMessage,
          m_type: 1,
          type: 1,
          user_details: stringifiedUserData,
          is_delete: 0,
        };
        setChatData((prev) => [updatedData, ...prev]);
        socket?.emit("send-msg", updatedData);
      }
    } catch (error)
    {
      console.log("handle-Send-Msg:", error);
    }
  };

  const generateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.crossOrigin = "anonymous";
      video.muted = true;
      video.playsInline = true;
      video.currentTime = 2;

      video.onloadeddata = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth / 2;
        canvas.height = video.videoHeight / 2;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailBase64 = canvas.toDataURL("image/jpeg");
        resolve(thumbnailBase64);
      };

      video.onerror = (error) => reject(error);
    });
  };

  const handleDeleteMsg = async (chat_id, msg_id) => {
    try
    {
      let formData = new FormData();
      formData.append("chat_id", chat_id);
      formData.append("message_id", msg_id);
      formData.append("is_delete", "1");

      let res = await AXIOS.post(apiList.chat.deleteMsg, formData);

      if (res?.s)
      {
        setChatData((prevMessages) => {
          let updatedMessages = prevMessages.map((msg) =>
            msg?.id == msg_id ? { ...msg, is_delete: 1, m_type: 2, type: msg?.message_type } : msg
          );

          let updatedMsg = updatedMessages.find((msg) => msg.id == msg_id);

          let stringifiedUserData = JSON.stringify(updatedMsg?.user_details);
          const d = { ...updatedMsg, user_details: stringifiedUserData }
          socket?.emit("send-msg", d);
          return updatedMessages;
        });
      } else
      {
        toast.warn(res?.m);
      }
    } catch (error)
    {
      console.error("Error deleting message:", error);
    }
  };

  useEffect(() => {
    if (listData?.id)
    {
      fetchData(listData?.id, 0);
    }
  }, [listData]);

  useEffect(() => {
    if (listData?.id && inView && chatData?.length < totalData)
    {
      fetchData(listData?.id, chatData?.length);
    }
  }, [inView]);

  const byDate = chatData?.reduce((obj, item) => {
    const messageDate = moment(item?.created_at);
    let displayDate = messageDate.isSame(moment(), "day")
      ? "Today"
      : messageDate.isSame(moment().subtract(1, "days"), "day")
        ? "Yesterday"
        : messageDate.format("ll");
    obj[displayDate] = obj[displayDate] ? [...obj[displayDate], item] : [item];
    return obj;
  }, {});

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file)
    {
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (selectedFile?.type)
    {
      let isImage = selectedFile.type.startsWith("image");
      let isVideo = selectedFile.type.startsWith("video");
      let type = isImage ? 2 : isVideo ? 3 : 0;
      if (type != 0)
      {
        handleSendMessage(type);
      }
    } else
    {
      return;
    }
  }, [selectedFile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Community Chat Management </h2>
        </Typography>
      </Box>

      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 95px)",
            bgcolor: "#FD5D290F",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#FD5D290F",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <IconButton onClick={onBack}>
                   <ArrowBack />
                 </IconButton> */}
              <AvatarGroup max={2} sx={{ mx: 2 }}>
                {[
                  {
                    profile_img:
                      listData?.group_img &&
                      apiList.baseURL + listData?.group_img,
                    username: "Megalopolist",
                  },
                ].map((user, index) => (
                  <Avatar
                    key={index}
                    src={user?.profile_img ? user.profile_img : ""}
                    alt={user?.username}
                    sx={{ width: 40, height: 40 }}
                  />
                ))}
              </AvatarGroup>
              <Typography fontWeight={600}>{listData?.group_name}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 2,
              display: "flex",
              flexDirection: "column-reverse",
              position: "relative",
              height: "calc(100vh - 200px)",
            }}
          >
            {loading ? (
              <Box className="flexCenter" sx={{ height: "100%" }}>
                <CircularProgress style={{ color: "#fd5d29" }} />
              </Box>
            ) : Object.entries(byDate)?.length > 0 ? (
              Object.entries(byDate)?.map(([date, msgs]) => (
                <React.Fragment key={date}>
                  {/* Messages List */}

                  {msgs.map((msg) => {
                    const isSender = msg.sender_id == listData?.user_one_id;
                    return (
                      <Box key={msg.id}>
                        {/* Message Container */}
                        {msg?.is_delete == 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "end",
                              justifyContent: isSender
                                ? "flex-end"
                                : "flex-start",
                              // border: "1px solid green",
                            }}
                            onMouseEnter={() => setDotVisiblity(msg?.id)}
                            onMouseLeave={() => setDotVisiblity(null)}
                          >
                            {/* Avatar for received messages (left side) */}
                            {!isSender && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                  // border: "1px solid red",
                                  alignSelf: "end",
                                }}
                              >
                                <ChatMenuOptions
                                  msgId={msg.id}
                                  dotVisiblity={dotVisiblity}
                                  // handleDeleteMsg={() =>
                                  //   handleDeleteMsg(msg?.chat_id, msg?.id)
                                  // }
                                  isSender={isSender}
                                  handleDetailNav={() =>
                                    navigate(RouteList.userDetails, {
                                      state: {
                                        userId: msg?.user_details?.id,
                                      },
                                    })
                                  }
                                />
                                <Avatar
                                  src={
                                    msg?.user_details?.profile_img &&
                                    apiList.baseURL +
                                    msg?.user_details?.profile_img
                                  }
                                  sx={{ width: 20, height: 20 }}
                                />
                              </Box>
                            )}

                            {/* Message Box */}
                            <Box
                              sx={{
                                bgcolor: isSender ? "#FD5D29" : "#fff",
                                color: isSender ? "#fff" : "#333",
                                p: 1.5,
                                borderRadius: isSender
                                  ? "15px 15px 0px 15px"
                                  : "15px 15px 15px 0",
                                maxWidth: "50%",
                                boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                                // ml: isSender ? "auto" : 0,
                                display:
                                  msg?.message_type == 1 ? "flex" : "none",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "18px" }}
                              >
                                {msg?.message}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                // bgcolor: isSender ? "#FD5D29" : "#fff",
                                color: isSender ? "#fff" : "#333",
                                p: 1.5,
                                borderRadius: isSender
                                  ? "15px 15px 0px 15px"
                                  : "15px 15px 15px 0",
                                maxWidth: "50%",
                                boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                                ml: isSender ? "auto" : 0,
                                display:
                                  msg?.message_type == 2 ? "flex" : "none",
                              }}
                            >
                              <img
                                src={msg?.media && apiList.baseURL + msg?.media}
                                width={220}
                                alt="upload"
                                style={{
                                  cursor: "pointer",
                                  minHeight: "100px",
                                }}
                                onClick={() =>
                                  window.open(
                                    `${apiList.baseURL + msg?.media}`,
                                    "_blank"
                                  )
                                }
                              />
                            </Box>
                            <Box
                              sx={{
                                // bgcolor: isSender ? "#FD5D29" : "#fff",
                                color: isSender ? "#fff" : "#333",
                                p: 1.5,
                                borderRadius: isSender
                                  ? "15px 15px 0px 15px"
                                  : "15px 15px 15px 0",
                                maxWidth: "50%",
                                boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                                ml: isSender ? "auto" : 0,
                                display:
                                  msg?.message_type == 3 ? "flex" : "none",
                                position: "relative",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={msg?.thumb && apiList.baseURL + msg?.thumb}
                                width={220}
                                alt="video thumbnail"
                                style={{
                                  cursor: "pointer",
                                  borderRadius: "9px",
                                  minHeight: "100px",
                                }}
                                onClick={() =>
                                  window.open(
                                    `${apiList.baseURL + msg?.media}`,
                                    "_blank"
                                  )
                                }
                              />
                              <PlayCircleFilledIcon
                                style={{
                                  cursor: "pointer",
                                  color: "#fff",
                                  fontSize: "36px",
                                  position: "absolute",
                                }}
                                onClick={() =>
                                  window.open(
                                    `${apiList.baseURL + msg?.media}`,
                                    "_blank"
                                  )
                                }
                              />
                            </Box>

                            {/* Chat Options & Avatar for Sent Messages (Right Side) */}
                            {isSender && (
                              <Box
                                sx={{
                                  height: "100%",
                                  minHeight: "100%",
                                  // border: "1px solid red",
                                }}
                              >
                                <ChatMenuOptions
                                  msgId={msg.id}
                                  dotVisiblity={dotVisiblity}
                                  handleDeleteMsg={() =>
                                    handleDeleteMsg(msg?.chat_id, msg?.id)
                                  }
                                  isSender={isSender}
                                />
                                <Avatar
                                  src={logo}
                                  sx={{ width: 20, height: 20 }}
                                />
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              // display: msg?.is_delete == 1 ? "flex" : "none",
                              display: "none",
                              gap: 1,
                              alignItems: "center",
                              justifyContent: isSender
                                ? "flex-end"
                                : "flex-start",
                            }}
                          >
                            {/*---------------------------- Deleted Message Box UI ----------------------------*/}
                            {!isSender && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                  // border: "1px solid red",
                                  alignSelf: "end",
                                }}
                              >
                                <Avatar
                                  src={
                                    msg?.user_details?.profile_img &&
                                    apiList.baseURL +
                                    msg?.user_details?.profile_img
                                  }
                                  sx={{ width: 20, height: 20 }}
                                />
                              </Box>
                            )}

                            {/* Message Box */}
                            <Box
                              sx={{
                                // bgcolor: isSender ? "#cecece" : "#fff",
                                bgcolor: "#cecece",
                                color: "#fff",
                                my: 0.7,
                                p: 1.5,
                                borderRadius: isSender
                                  ? "15px 15px 0px 15px"
                                  : "15px 15px 15px 0",
                                maxWidth: "50%",
                                boxShadow: "0px 1px 3px rgba(0,0,0,0.2)",
                                display: msg?.is_delete == 1 ? "flex" : "none",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "18px" }}
                              >
                                This Message was deleted.
                              </Typography>
                            </Box>

                            {/* Chat Options & Avatar for Sent Messages (Right Side) */}
                            {isSender && (
                              <Box
                                sx={{
                                  display:
                                    msg?.is_delete == 1 ? "flex" : "none",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                  // border: "1px solid red",
                                  alignSelf: "end",
                                }}
                              >
                                <Avatar
                                  src={logo}
                                  sx={{ width: 20, height: 20 }}
                                />
                              </Box>
                            )}
                          </Box>
                        )}
                        {/* Timestamp */}
                        <Box
                          sx={{
                            display: msg?.is_delete == 0 ? "flex" : "none",
                            gap: 1,
                            alignItems: "start",
                            justifyContent: isSender ? "end" : "start",
                          }}
                          key={"time-" + msg?.id}
                        >
                          {!isSender && <Box sx={{ width: 24, height: 24 }} />}
                          <Typography
                            variant="caption"
                            sx={{
                              opacity: 0.6,
                              fontSize: "12px",
                              // textAlign: isSender ? "right" : "left",
                            }}
                          >
                            {moment(msg.created_at).format("LT")}
                          </Typography>
                          {isSender && <Box sx={{ width: 24, height: 24 }} />}
                        </Box>
                      </Box>
                    );
                  })}
                  <Box
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      zIndex: 10,
                      textAlign: "center",
                      my: 1,
                      // border: "1px solid red"
                    }}
                  >
                    <span
                      style={{
                        background: "#F3F2F2",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {date}
                    </span>
                  </Box>
                </React.Fragment>
              ))
            ) : (
              <Box className="flexCenter">
                <span
                  style={{
                    background: "#F3F2F2",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    color: "#fd5d29",
                  }}
                >
                  No chat found
                </span>
              </Box>
            )}
            <div
              ref={ref}
              style={{
                textAlign: "center",
                visibility: fetchingMore && !loading ? "visible" : "hidden",
              }}
            >
              {fetchingMore && (
                <CircularProgress style={{ color: "#fd5d2955" }} />
              )}
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              gap: 1,
              bgcolor: "transparent",
              // boxShadow: "0px -2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <label>
              <AddIcon
                sx={{
                  cursor: "pointer",
                  background: "#fd5d29",
                  borderRadius: "50%",
                  ml: 1,
                  fontSize: "33px",
                  p: 0.6,
                  color: "#fff",
                }}
              />
              <input
                type="file"
                accept="image/*,video/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </label>

            <InputBase
              sx={{
                flex: 1,
                borderRadius: "10px",
                bgcolor: "#FFF",
                border: "1px solid #00000014",
                px: 2,
                py: 1,
              }}
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage(1)}
            />
            <IconButton
              color="primary"
              onClick={() => handleSendMessage(1)}
              sx={{
                background: "#Fd5d29",
                "&:hover": { background: "#Fd5d29", outline: "2px solid #FFF" },
              }}
            >
              <Send sx={{ transform: "rotate(-45deg)", color: "#FFF" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
