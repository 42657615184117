import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import defaultUserImage from "../Assets/Image/default.webp";
import { apiList } from "./apiList";
import delet from "../Assets/Image/userDetails/deleteUser.png";
// import block from "../Assets/Image/userDetails/unblock.png";
// import unblock from "../Assets/Image/userDetails/userBlocked.png";
import reset from "../Assets/Image/userDetails/synchronize.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteList } from "./RoutesList";
import { AXIOS } from "./apiHelper";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ConfirmationModal1 from "./ConfirmModal1";
import { StatusList } from "./StatusList";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

const UserDetailsSidebar = ({ data, getData }) => {
  const nav = useNavigate();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const isUserScreen = location.pathname === RouteList.userDetails;
  const isBusinessScreen = location.pathname === RouteList.businessDetails;
  const isPostDetailsScreen = location.pathname === RouteList.listDetails;
  let isParent;
  if (isUserScreen)
  {
    isParent = RouteList.user;
  } else if (isBusinessScreen)
  {
    isParent = RouteList.business;
  } else if (isPostDetailsScreen)
  {
    isParent = RouteList.list;
  }
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const DRAWER_WIDTH = 270;
  const toggleDrawer = () => setMobileOpen(!mobileOpen);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    currentStatus: null,
    feedId: null,
  });

  const handleResetPassword = async (email) => {
    try
    {
      const f = new FormData();
      f.append("email", email);
      setloading(true);
      const res = await AXIOS.post(apiList.auth.sendResetPasswordLink, f);
      if (res?.s)
      {
        toast.success(res?.m);
      }
      setloading(false);
    } catch (error)
    {
      console.log(error);
      setloading(false);
    }
  };

  const getStatusMessage = (status, type) => {
    const statusItem = StatusList.find((item) => item.code === status);
    return statusItem
      ? type === "toast"
        ? statusItem.toast
        : statusItem.message
      : "Are you sure?";
  };

  const handleAction = async (userId, status) => {
    let toastMessage = getStatusMessage(status, "toast", "user");
    try
    {
      setIsActionLoading(true);
      const f = new FormData();
      f.append("status", status);
      f.append("user_id", userId);

      const res = await AXIOS.post(`${apiList.user.action}`, f);
      if (res?.s)
      {
        toast.success(toastMessage);
        await getData();
        if (status === -3) nav(isParent);
      } else
      {
        toast.error(res?.m);
      }
      setIsActionLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsActionLoading(false);
    }
  };

  const handleDeletePost = async (id) => {
    try
    {
      setIsActionLoading(true);
      const res = await AXIOS.get(`${apiList.list.delete}?feed_id=${id}`);
      if (res?.s)
      {
        toast.success("List deleted successfully");
        nav(RouteList.list);
      } else
      {
        toast.error(res?.m);
      }
      setIsActionLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsActionLoading(false);
    }
  };

  const Details = (
    <Box
      sx={{
        height: "100dvh",
        width: "270px",
        background: "#FFF6F3",
        p: 3,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100px",
            height: "100px",
            border: "2px solid #292D3280",
            borderRadius: "50%",
            mt: 3,
          }}
        >
          <img
            src={
              data?.profile_img
                ? apiList.baseURL  + data?.profile_img
                : defaultUserImage
            }
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt="Profile"
          />
        </Box>
        <Box
          sx={{
            border: "1px solid #FD5D296B",
            background: "#FFF",
            borderRadius: "15px",
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          {[
            { lable: "User Name", data: data?.username },
            {
              lable: "User Email",
              data: data?.email,
              link: `mailto:${data?.email}`,
            },
            {
              lable: "User Phone",
              data: data?.phno ? data?.phno_cc + " " + data?.phno : "-",
            },
            {
              lable: "Address",
              data: data?.city_name
                ? data?.city_name +
                ", " +
                data?.state_name +
                ", " +
                data?.country_name +
                "- " +
                data?.zipcode
                : "-",
            },
          ].map((x, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ color: "#FD5D29", fontSize: "14px" }}>
                {x?.lable}
              </Typography>
              {x?.link ? (
                <a
                  href={x?.link}
                  style={{
                    color: "#292D32",
                    fontWeight: 600,
                    cursor: "pointer",
                    wordBreak: "break-all",
                    textAlign: "center",
                  }}
                >
                  {x?.data}
                </a>
              ) : (
                <Typography
                  sx={{
                    color: "#292D32",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {x?.data}
                </Typography>
              )}
            </Box>
          ))}
          {/* <Box
            sx={{
              background: "#FFE8E1",
              height: "100%",
              width: "100%",
              borderRadius: "15px",
              display:
                data?.role === 2 && isUserScreen
                  ? data?.user_business?.id
                    ? "flex"
                    : "none"
                  : data?.role === 2 && data?.id
                  ? "flex"
                  : isPostDetailsScreen
                  ? "flex"
                  : "none",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              p: 2,
            }}
          > */}
          {/* <Box>{isUserScreen ? "Business Details" : "User Details"}</Box> */}

          <Button
            onClick={() =>
              !isUserScreen
                ? nav(RouteList.userDetails, {
                  state: {
                    userId: data?.id,
                  },
                })
                : nav(RouteList.businessDetails, {
                  state: {
                    businessId: data?.user_business?.id,
                  },
                })
            }
            fullWidth
            sx={{
              borderRadius: "9px",
              background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
              textTransform: "none",
              display:
                data?.role === 2 && isUserScreen
                  ? data?.user_business?.id
                    ? "flex"
                    : "none"
                  : data?.role === 2 && data?.id
                    ? "flex"
                    : isPostDetailsScreen
                      ? "flex"
                      : "none",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              color: "#FFF",
              width: "fit-content",
              padding: "9px 20px",
            }}
          >
            {isUserScreen ? "Business Details" : "User Details"}
          </Button>
          {/* </Box> */}
        </Box>
      </Box>
      <Box className="flexCol" sx={{ width: "100%" }}>
        <Button
          onClick={() => {
            handleResetPassword(data?.email);
          }}
          disabled={data?.status === 0 || loading}
          sx={{
            borderRadius: "15px",
            //   background: "#FFE7E5",
            background: "#FFF",
            textTransform: "none",
            display:
              data?.auth_type === 1 && !isPostDetailsScreen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            color: "#292D32",
            width: "100%",
            padding: "15px 20px",
          }}
        >
          <img src={reset} alt="" style={{ height: "20px", width: "20px" }} />{" "}
          {!loading ? "Reset Password" : <CircularProgress size={26} />}
        </Button>
        <Button
          onClick={() => {
            setOpen(true);
            setActionParams({
              userId: data?.id,
              status: -1,
              feedId: data?.feed_id,
            });
          }}
          fullWidth
          disabled={data?.status === 0}
          sx={{
            borderRadius: "15px",
            background: "#FFE7E5",
            textTransform: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            color: "#FF0000",
            width: "100%",
            padding: "15px 20px",
          }}
        >
          <img src={delet} alt="" style={{ height: "20px", width: "20px" }} />{" "}
          {isPostDetailsScreen ? "Delete Post" : "Delete Account"}
        </Button>
      </Box>
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        loading={isActionLoading}
        onConfirm={() =>
          isPostDetailsScreen
            ? handleDeletePost(actionParams.feedId)
            : handleAction(actionParams.userId, actionParams.status)
        }
        title="Confirmation"
        message={
          isPostDetailsScreen
            ? "Are you sure want to delete post?"
            : getStatusMessage(actionParams.status)
        }
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          ml: { md: `${DRAWER_WIDTH}px` },
          zIndex: 1,
          display: { md: "none" },
          backgroundColor: "#FD5d29",
          border: "1px solid red",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" noWrap component="div">
            Megalopolist Admin
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ ml: 2, display: { md: "none" } }}
          >
            <AccountCircleRoundedIcon
              style={{
                fontSize: "28px",
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block", md: "none" }, // Show only on mobile
        }}
      >
        <Box
          sx={{
            width: DRAWER_WIDTH,
            background: "#FFF6F3",
            height: "100%",
            // p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Details}
        </Box>
      </Drawer>

      <Box
        sx={{
          display: { xs: "none", md: "block" }, // Hide on mobile
          width: DRAWER_WIDTH,
          background: "#FFF6F3",
        }}
      >
        {Details}
      </Box>
    </>
  );
};

export default UserDetailsSidebar;
