import { Box, Button, Tooltip, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";

import defaultImage from "../../Assets/Image/logo.webp";
import child from "../../Assets/Image/child.png";
import edit from "../../Assets/Image/edit.webp";
import deleteCat from "../../Assets/Image/delete.webp";
import CategoryModal from "./CategoryManageModal";
import SUbCategoryScreen from "./SubCategoryManagement";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import UploadModal from "./UploadModal";

const CategoryScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  //PARENT CODE
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [actionParams, setActionParams] = useState(0);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  // const [level, setLevel] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleDataSubmit = (data) => {
    console.log("Excel Data as JSON:", data);
    // Further processing of JSON data
  };
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 30,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      renderCell: ({ row }) => (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {row?.rowid + page * rowsPerPage}
        </div>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 50,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={
              cell?.row?.image
                ? apiList.baseURL + cell?.row?.image
                : defaultImage
            }
            alt="cate_image"
            style={{ height: "25px", width: "25px" }}
          />
        </div>
      ),
    },
    {
      field: "category_name",
      headerName: "Main Category ",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      display: "flex",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title={params.value || ""} arrow>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Action",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <Box className="flexCenter" sx={{ height: "100%", gap: 1 }}>
              <img
                src={edit}
                alt="Block"
                style={{
                  height: "25px",
                  width: "25px",
                  display: edit_access ? "block" : "none",
                }}
                onClick={() => {
                  setModalData(cell?.row);
                  setModalType("update-main");
                }}
              />
              <img
                src={deleteCat}
                style={{
                  height: "25px",
                  width: "25px",
                  display: delete_access ? "block" : "none",
                }}
                alt="delete"
                onClick={() => {
                  setOpen(true);
                  setActionParams(cell?.row?.id);
                }}
              />
              <img
                src={child}
                style={{ height: "25px", width: "25px" }}
                alt="child"
                onClick={() => {
                  // setLevel(1);
                  setSubCategoriesData((prev) => [
                    {
                      id: cell?.row?.id,
                      label: cell?.row?.category_name,
                      level: 1,
                    },
                  ]);
                }}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (search !== prevSearch) {
        setData([]);
        setPage(0);
      }
      let searchParams =
        search?.length > 0
          ? `parent_id=0&search=${search?.trim()}`
          : `parent_id=0`;
      const res = await AXIOS.get(
        `${apiList.category.getAll}?${searchParams}&skip=${count}&limit=${rowsPerPage}`
      );
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      setLoading(true);
      const res = await AXIOS.post(`${apiList.category.deleteMainCat}`, {
        id: id,
      });
      if (res?.s) {
        setSubCategoriesData([]);
        toast.error("Deleted successfully");
        await getData(
          page,
          rowsPerPage,
          debouncedSearchTerm,
          searchRef.current
        );
        setLoading(false);
      } else {
        toast.error(res?.m);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);

  useEffect(() => {
    getData(page, rowsPerPage, debouncedSearchTerm, searchRef.current);
    searchRef.current = debouncedSearchTerm;
  }, [debouncedSearchTerm, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };

  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const third = accesslist?.find((module) => module.id === 14);
  const add_access = !!third?.access?.find(
    (access) => access.id === 23 && access.admin_user_id
  );
  const edit_access = !!third?.access?.find(
    (access) => access.id === 24 && access.admin_user_id
  );
  const delete_access = !!third?.access?.find(
    (access) => access.id === 25 && access.admin_user_id
  );

  const UserData = JSON.parse(localStorage.getItem("Megalopolist-userData"));
  const getAccessList = async (userId) => {
    try {
      let res = await AXIOS.get(apiList?.subAdmin?.accessbyuserID, {
        params: { user_id: userId },
      });
      if (res?.r) {
        localStorage.setItem(
          "Megalopolist-userData-roles",
          JSON.stringify(res?.r)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    UserData?.id && getAccessList(UserData?.id);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        height: "calc(100dvh - 50px)",
      }}
    >
      {/* <button onClick={handleUpload} disabled={isUploaded} style={{padding:15}}>
        {isUploaded ? "Upload Complete" : d[0]?.name}
      </button> */}
      <Box className="flexBetween">
        <Typography>
          <h2> Category Management </h2>
        </Typography>
        <Button
          variant="contained"
          onClick={handleModalOpen}
          sx={{
            width: "150px",
            textTransform: "none",
            borderRadius: "15px",
            fontSize: "17px",
            color: "#FFF",
            background: "linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)",
            "&:hover": {
              background: "#00075",
            },
            padding: "8px",
          }}
        >
          Upload
        </Button>
        <UploadModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleDataSubmit={handleDataSubmit}
          getData={getData}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          overflowX: "auto",
          overflowY: "hidden",
          height: "calc(100% - 50px)",
          whiteSpace: "nowrap",
          justifyContent: "start",
          alignItems: "start",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "500px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              <h4>Category </h4>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{}}>
                <Button
                  sx={{
                    display: add_access ? "block" : "none",
                    width: "150px",
                    textTransform: "none",
                    borderRadius: "15px",
                    fontSize: "17px",
                    color: "#FFF",
                    background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                    "&:hover": {
                      background: "#00075",
                    },
                    padding: "8px",
                  }}
                  onClick={() => {
                    setModalData("0");
                    setModalType("add-main");
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <input
              type="search"
              name="search"
              value={searchTerm?.trimStart()}
              placeholder="Search by name"
              style={{
                padding: "13px",
                height: "100%",
                borderRadius: "12px",
                border: "1px solid #FD5D29",
                backgroundColor: "#FFF6F3",
                color: "#FD5D29",
                width: "100%",
              }}
              onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
              id="search"
            />
          </Box>
          <Box sx={{ height: "calc(100dvh - 270px)" }}>
            <DataListingComponent
              data={data}
              PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
              loading={loading}
              totalData={totalData}
              page={page}
              columns={columns}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Box>

        {subCategoriesData?.map((child, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "500px",
            }}
          >
            <SUbCategoryScreen
              // setLevel={setLevel}
              index={index}
              id={child.id}
              label={child.label}
              // level={level}
              setSubCategoriesData={setSubCategoriesData}
              subCategoriesData={subCategoriesData}
            />
          </Box>
        ))}
      </Box>
      <CategoryModal
        data={modalData}
        open={modalData}
        setOpen={setModalData}
        type={modalType}
        getData={() =>
          getData(
            page,
            rowsPerPage,
            // filter,
            // filterRef.current,
            debouncedSearchTerm,
            searchRef.current
          )
        }
      />
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        onConfirm={() => handleDeleteCategory(actionParams)}
        title="Confirm Action"
        message="Are you sure want to delete category?"
        confirmText="Confirm"
        cancelText="Cancel"
        loading={loading}
      />
    </Box>
  );
};

export default CategoryScreen;
