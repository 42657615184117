import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import userIcon from "../../Assets/Image/dashUser.png";
import listIcon from "../../Assets/Image/dashList.png";
import revIcon from "../../Assets/Image/dashRev.png";
import business from "../../Assets/Image/business.png";
import flag from "../../Assets/Image/Flag.png";
import { hasAccess } from "../../Component/CheckAccess";

function AnalyticCards({ data, loading }) {
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "K";
    return num.toString();
  };
// ihoho
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1.5rem",
        flexWrap: { xs: "wrap", lg: "wrap" },
        width:"100%"
      }}
    >
      {hasAccess(1, 1) && (
        <Box
           sx={{
              width: { xs: "100%", md: "200px" },
              borderRadius: "14px",
              padding: "20px",
              background: "#FFF6F3",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={userIcon} alt="Total Users" height={30} width={30} />
            <Typography variant="h6" sx={{ fontSize: { xs: "1rem", xl: "1rem" } }}>Total Users</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              formatNumber(data?.total_user ?? 0)
            )}
          </Typography>
        </Box>
      )}

      {hasAccess(1, 2) && (
        <Box
           sx={{
              width: { xs: "100%", md: "200px" },
              borderRadius: "14px",
              padding: "20px",
              background: "#FFF6F3",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={listIcon} alt="Total Listings" height={30} width={30} />
            <Typography variant="h6" sx={{ fontSize: { xs: "1rem", xl: "1rem" } }}>Total Listings</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              formatNumber(data?.total_listing ?? 0)
            )}
          </Typography>
        </Box>
      )}

      {hasAccess(1, 3) && (
        <Box
           sx={{
              width: { xs: "100%", md: "200px" },
              borderRadius: "14px",
              padding: "20px",
              background: "#FFF6F3",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={business} alt="Total Businesses" height={30} width={30} />
            <Typography variant="h6" sx={{ fontSize: { xs: "1rem", xl: "1rem" } }}>Total Businesses</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              formatNumber(data?.total_business ?? 0)
            )}
          </Typography>
        </Box>
      )}

      {hasAccess(1, 4) && (
        <Box
           sx={{
              width: { xs: "100%", md: "200px" },
              borderRadius: "14px",
              padding: "20px",
              background: "#FFF6F3",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={flag} alt="Flagged Users" height={30} width={30} />
            <Typography variant="h6" sx={{ fontSize: { xs: "1rem", xl: "1rem" } }}>Flagged Users</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              formatNumber(data?.total_user_flagged ?? 0)
            )}
          </Typography>
        </Box>
      )}

      {hasAccess(1, 5) && (
        <Box
           sx={{
              width: { xs: "100%", md: "200px" },
              borderRadius: "14px",
              padding: "20px",
              background: "#FFF6F3",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <img src={revIcon} alt="Total Revenue" height={30} width={30} />
            <Typography variant="h6" sx={{ fontSize: { xs: "1rem", xl: "1rem" } }}>Total Revenue</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "28px", fontWeight: "700", letterSpacing: "1px" }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              `$${formatNumber(data?.total_revenue ?? 0)}`
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default AnalyticCards;
