import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import moment from "moment";
import NoDataImg from "../../Component/NoDataImage";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";

const NotificationManagementScreen = () => {
  const [notificationsList, setNotificationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // const [moreData, setMoreData] = useState(false);
  let dataLength = 0;
  const { ref, inView } = useInView();

  const formik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      type: 3,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required.")
        .max(50, "Max 50 characters allowed."),
      desc: Yup.string()
        .required("Description is required.")
        .max(240, "Max 240 characters allowed."),
      type: Yup.number().required("Type is required."),
    }),

    onSubmit: async (value, { resetForm }) => {
      try {
        let f = new FormData();
        f.append("title", value.title);
        f.append("description", value.desc);
        f.append("user_type", value?.type);
        f.append("sent_time", moment().format("hh:mm"));
        f.append("sent_date", moment().format("YYYY/MM/DD"));
        f.append("notification_type", 2);
        let res = await AXIOS.post(apiList.notification.sentBulk, f);
        if (res?.s) {
          toast.success("Notification sent successfully");
          fetchNotificationsHistory(0, 3);
          resetForm();
        } else {
          toast.error(res?.m);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const fetchNotificationsHistory = async (id, type, count) => {
    try {
      setIsLoadingMore(true);
      const res = await AXIOS.get(apiList.notification.getAll, {
        params: {
          user_id: id,
          user_type: type,
        },
      });
      res?.r?.length > 0 && setNotificationsList(res?.r);
      setIsLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationsHistory(0, 3, 0);
  }, []);

  useEffect(() => {
    if (notificationsList.length && inView) {
      //eslint-disable-next-line
      dataLength = notificationsList?.length;
      fetchNotificationsHistory(0, 3, dataLength);
    }
  }, [inView]);

  const byDate = (notificationsList || []).reduce((obj, item) => {
    const messageDate = moment(item?.updated_at);
    let displayDate;

    if (messageDate.isSame(moment(), "day")) {
      displayDate = "Today";
    } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
      displayDate = "Yesterday";
    } else {
      displayDate = messageDate.format("DD/MM/YYYY");
    }

    if (obj[displayDate]) {
      obj[displayDate].push(item);
    } else {
      obj[displayDate] = [{ ...item }];
    }

    return obj;
  }, {});

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Notification Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          gap: 2,
          height: "calc(100vh - 100px)",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", lg: "60%" },
            py: 1,
            pt: { xs: "460px", lg: "15px" },
          }}
        >
          {/* <Typography variant="h6" sx={{ fontSize: "28px", fontWeight: "500" }}>
            Notifications
          </Typography> */}
          {/* <form style={{ width: "100%" }} > */}
          <Typography sx={{ fontSize: "16px", fontWeight: "500", mb: 0.5 }}>
            Notification Title
          </Typography>
          <TextField
            type="text"
            name="title"
            id="title"
            value={formik?.values?.title}
            onChange={formik?.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            placeholder="Notification Title"
            variant="outlined"
            fullWidth
            focused
            sx={{
              ".MuiInputBase-root": {
                borderRadius: "12px",
                background: "#00000005",
                color: "#000",
                border: "1px solid #00000033",
              },
              ".MuiOutlinedInput-input": {
                padding: "14px",
              },

              "& input[type='password']::-ms-reveal": {
                display: "none",
              },
              "& input[type='password']::-ms-clear": {
                display: "none",
              },

              ".MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
          />
          {formik?.errors.title && (
            <Typography sx={{ color: "red", fontSize: "14px" }}>
              {formik?.errors.title}
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              mt: 1,
              mb: 0.5,
              color: "#000000CC",
            }}
          >
            Description
          </Typography>
          <TextField
            type="text"
            name="desc"
            multiline
            value={formik?.values?.desc}
            onChange={formik?.handleChange}
            placeholder="Description"
            fullWidth
            minRows={4}
            maxRows={8}
            sx={{
              // margin: "0.25rem 0 0 0",
              ".MuiInputBase-root": {
                borderRadius: "15px",
                background: "#00000005",
                color: "#000",
                border: "1px solid #00000033",
              },
              // ".MuiOutlinedInput-input": {
              //   padding: "14px",
              // },

              "& .MuiOutlinedInput-input:focus": {
                outline: "none",
              },
              fieldset: {
                border: "none",
              },
            }}
          />
          {formik?.errors.desc && (
            <Typography sx={{ color: "red", fontSize: "14px" }}>
              {formik?.errors.desc}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "space-between",
              alignItems: "centre",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {/* <Box sx={{ width: "50%" }}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", mt: 1,color:'#000000CC',}}
              >
                Select
              </Typography>
              <Select
                name="Select"
                value={data.Select}
                onChange={(e) => handleChange(e, "time")}
                defaultValue={2}
                fullWidth
                sx={{
                  borderRadius: "8px",
                  ".MuiSelect-select": {
                    padding: "10px 12px",
                  },
                }}
              >
                {["Schedule", "Now"].map((x, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={i + 1}
                      style={{ display: x === "None" ? "none" : "block" }}
                    >
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik?.errors.Select && (
                <Typography sx={{ color: "red", fontSize: "14px" }}>
                  {formik?.errors.Select}
                </Typography>
              )}
            </Box>{" "} */}
            <Box sx={{ width: "250px" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  mt: 1,
                  mb: 0.5,
                  color: "#000000CC",
                }}
              >
                Notification For
              </Typography>
              <Select
                name="type"
                value={formik?.values?.type}
                onChange={formik?.handleChange}
                fullWidth
                sx={{
                  // margin: "0.25rem 0 0 0",
                  ".MuiInputBase-root": {
                    border: "1px solid #FD5D2966",
                  },
                  ".MuiOutlinedInput-input": {
                    background: "#00000005",
                    color: "#000",
                    borderRadius: "15px",
                    border: "1px solid #00000033",
                    padding: "14px",
                  },

                  "& .MuiOutlinedInput-input:focus": {
                    outline: "none",
                  },
                  fieldset: {
                    border: "none",
                  },
                }}
              >
                {[
                  { lable: "All", key: 3 },
                  { lable: "Users", key: 1 },
                  { lable: "Business", key: 2 },
                ].map((x, i) => {
                  return (
                    <MenuItem defaultValue={0} key={i} value={x?.key}>
                      {x?.lable}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              pt: 5,
            }}
          >
            <Button
              variant="contained"
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              sx={{
                width: "100%",
                maxWidth: "250px",
                textTransform: "none",
                borderRadius: "15px",
                fontSize: "17px",
                background: `#FD5D29`,
                "&:hover": {
                  background: "#00075",
                },
                padding: "12px",
              }}
            >
              {formik.isSubmitting ? "Sending..." : "Send"}
              &nbsp;
              {/* {!formik.isSubmitting && <EastRoundedIcon />} */}
            </Button>
          </Box>
          {/* </form> */}
        </Box>
        <hr />
        <Box
          sx={{
            width: { xs: "100%", lg: "40%" },
            border: "1px solid #FD5D291A",
            // p: 2,
            borderRadius: "12px",
            position: "relative",
          }}
        >
          <Typography>
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottom: "1px solid #febba550",
                padding: "16px",
              }}
            >
              {" "}
              Notification History{" "}
            </h3>
          </Typography>
          {isLoading ? (
            <Box
              className="flexCenter"
              sx={{
                height: "calc(100vh - 160px)",
              }}
            >
              <CircularProgress style={{ color: "#fd5d29" }} />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                overflow: "scroll",
                height: "calc(100vh - 160px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {notificationsList?.length > 0 ? (
                <>
                  {Object.entries(byDate).map(([date, x], i) => (
                    <>
                      <Box
                        sx={{
                          background: `#F3F2F2`,
                          color: "#000000B2",
                          // marginTop: { xs: "20px", lg: "-2px" },
                          // fontWeight: "700",
                          fontSize: "16px",
                          textAlign: "center",
                          p: 1,
                          // borderRadius: "8px",
                          position: "sticky",
                          top: 0,
                          my: 2,
                        }}
                        key={i}
                      >
                        {date}
                      </Box>
                      {x?.map((list, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              width: "95%",
                              // background: "#FD5D2966",
                              background: "#FFEFEA",
                              borderRadius: "10px",
                              display: "flex",
                              alignItems: "start",
                              p: 1,
                              my: 1,
                              mx: "auto",
                              border: "1px solid #FD5D2980",
                            }}
                          >
                            {/* <Box>
                            <img src={notificationicon} alt="fsgsfgs" />
                          </Box> */}
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    pl: 1,
                                    fontSize: "16px",
                                    // fontWeight: 600,
                                    color: "text.primary",
                                    wordBreak: "break-all",
                                    width: "calc(100% - 100px)",
                                    // border: "1px solid red"
                                  }}
                                >
                                  <span>{list?.title}</span>
                                  <Box
                                    component="span"
                                    style={{
                                      ml: 0.5,
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      color: "#000000CC",
                                      width: "100px",
                                    }}
                                  >
                                    {list?.user_type &&
                                      {
                                        3: "(All)",
                                        2: "(Business)",
                                        1: "(Users)",
                                      }[list.user_type]}
                                  </Box>
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "80px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      pl: 1,
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      color: "#00000080",
                                    }}
                                  >
                                    {moment(list?.created_at)
                                      .local()
                                      .format("hh:mm A")}
                                  </Typography>
                                </Box>
                              </Box>

                              <Typography
                                sx={{
                                  p: 1,
                                  fontSize: "14px",
                                  width: "100%",
                                  wordBreak: "break-all",
                                  color: "#000000CC",
                                }}
                              >
                                {list?.description}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                      mt: "1rem",
                    }}
                    ref={ref}
                  >
                    {isLoadingMore && <CircularProgress color="error" />}
                  </Box>
                </>
              ) : (
                <Box
                  className="flexCenter"
                  sx={{
                    height: "calc(100vh - 160px)",
                  }}
                >
                  <NoDataImg />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationManagementScreen;
