import React, { useEffect, useState } from "react";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import DataListingComponent from "../../Component/DataListGrid";
import { Box, Typography } from "@mui/material";
import { apiList } from "../../Component/apiList";
// import dlt from "../../Assets/Image/delete.webp";
// import RatingStar from "../../Component/RatingStarComponent";
// import moment from "moment";
import logo from "../../Assets/Image/question.webp";
// import defaultUserImage from "../../Assets/Image/default.webp";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import BasicMenu from "../../Component/MenuOptioinComponent";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";
const CommunityModeration = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState(99);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState("");

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "id",
      headerName: "Id",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "community_name",
      headerName: "Community Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      // renderCell: (cell) => cell?.row?.user_report_by?.username,
    },
    {
      field: "report_to_detail",
      headerName: "Report To",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => cell?.row?.report_to_detail?.username,
    },

    {
      field: "message",
      headerName: "Message",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: ({ row }) => {
        let x = row?.message_type;
        let src = x === 3 ? row?.thumb : x === 2 && row?.media;
        if (x === 1)
        {
          return row?.message;
        } else
        {
          return (
            <img
              src={src && apiList.baseURL + src}
              width={40}
              height={40}
              alt="video thumbnail"
              style={{
                cursor: "pointer",
                borderRadius: "8px",
              }}
              onClick={() =>
                window.open(`${apiList.baseURL + row?.media}`, "_blank")
              }
            />
          );
        }
      },
    },

    {
      field: "report_count",
      headerName: "Report Count",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
    },

    {
      field: "status",
      headerName: "View",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const menuOptions = [
          {
            action: () =>
              navigate(RouteList.ModerationDetails, {
                state: {
                  id: cell?.row?.message_id,
                  type: 3,
                },
              }),
            title: "Details",
            isDisabled: false,
            icon: <DescriptionIcon style={{ color: "#fd5d29" }} />,
            isActive: true,
          },
          {
            action: () =>
              navigate(RouteList.userDetails, {
                state: {
                  userId: data?.id,
                },
              }),
            title: `User Detail`,
            isDisabled: false,
            icon: <PersonIcon style={{ color: "#fd5d29" }} />,
            isActive: true,
          },
          // {
          //   action: () => handleDeleteMessage(data),
          //   title: `Delete message`,
          //   isDisabled: false,
          //   icon: <DeleteIcon style={{ color: "#fd5d29" }} />,
          //   // isActive: user_details_access ? true : false,
          // },
        ];
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <BasicMenu rowData={cell?.row} options={menuOptions} />
          </Box>
        );
      },
    },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch
  ) => {
    try
    {
      const count = page * rowsPerPage;
      setLoading(true);
      // if (filter !== prevFilter || search !== prevSearch) {
      //   setPage(0);
      //   setData([]);
      // }
      let arg = `?skip=${count}&limit=${rowsPerPage}`;
      // if (search?.length > 0) {
      //   arg += `&search=${search?.trim()}`;
      // }

      // if (filter !== 9 && filter !== 99) {
      //   arg += `&rating_point=${filter}`;
      // }

      const res = await AXIOS.get(
        `${apiList.CommunityModeration.getAll}${arg}`
      );
      if (res?.s)
      {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 + page * rowsPerPage };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else
      {
        setData([]);
        setPage();
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      //   filterRef.current,
      debouncedSearchTerm
      //   searchRef.current
    );
    // searchRef.current = debouncedSearchTerm;
    // filterRef.current = filter;
  }, [debouncedSearchTerm, filter, rowsPerPage, page]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2>Community Chat Moderation</h2>
        </Typography>
      </Box>

      <Box sx={{ height: "calc(100vh - 120px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
        //   handlePageChange={handlePageChange}
        //   handleRowsPerPageChange={handleRowsPerPageChange}
        />

        {/* <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          logo={logo}
          //   onConfirm={() => handleAction(actionParams)}
          title="Confirm Delete Action"
          message="Are you sure want to delete?"
          confirmText="Confirm"
          cancelText="Cancel"
        /> */}
      </Box>
    </Box>
  );
};

export default CommunityModeration;
