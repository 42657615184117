import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DataListingComponent from "../../Component/DataListGrid";
import view from "../../Assets/Image/newView.webp";
import deleted from "../../Assets/Image/delete.webp";
import { apiList } from "../../Component/apiList";
import moment from "moment";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import PostDetailsModal from "./PostDetailsModal";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import DropDownComponent from "../../Component/DropDownComponent";
import DatePickerComponent from "../../Component/DatePicker";
import { RouteList } from "../../Component/RoutesList";
import { useNavigate } from "react-router-dom";
import BasicMenu from "../../Component/MenuOptioinComponent";
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const AdvDataScreenTab = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [open, setOpen] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [postDetailsId, setPostDetailsId] = useState(0);
  const [actionParams, setActionParams] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState(99);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const navigate = useNavigate();

  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const third = accesslist?.find((module) => module.id === 3);
  const delete_access = !!third?.access?.find(
    (access) => access.id === 13 && access.admin_user_id 
  );
  const details_view_access = !!third?.access?.find(
    (access) => access.id === 28 && access.admin_user_id 
  );
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          onClick={() => details_view_access  &&
            navigate(RouteList.listDetails, {
              state: { id: cell?.row?.id },
            })
          }
        >
          <img
            src={
              cell?.row?.feed_media[0]?.thumb
                ? apiList.baseURL + cell?.row?.feed_media[0]?.thumb
                : "https://www.dummyimage.co.uk/50x50/cbcbcb/959595/post/15"
            }
            alt="media"
            style={{ borderRadius: "9px", height: "40px", width: "40px" }}
          />
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Post Title",
      minWidth: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title={params.value || ""} arrow>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "category_name",
      headerName: "Category",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      filter: true,
      sort: true,
      renderCell: (cell) => cell?.row?.category_name ?? "-",
    },
    // {
    //   field: "category_sub_name",
    //   headerName: "Sub Category",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    //   renderCell: (cell) => cell?.row?.category_sub_name ?? "-",
    // },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.user_detail?.username
          ? cell?.row?.user_detail?.username
          : "-",
    },
    {
      field: "businessname",
      headerName: "Business Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.user_business?.business_name
          ? cell?.row?.user_business?.business_name
          : "-",
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.user_detail?.role === 1 ? "Individual" : "Business",
    },

    {
      field: "created_at",
      headerName: "Posted Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => moment(cell?.row?.created_at).format("DD/MM/YYYY"),
    },
    {
      field: "expired_at",
      headerName: "Expiry Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.expired_at
          ? moment(cell?.row?.expired_at).format("DD/MM/YYYY")
          : "-",
    },
    {
      field: "is_expired",
      headerName: "Status",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.is_expired === 0 ? (
          <span style={{ color: "green" }}>Active</span>
        ) : (
          <span style={{ color: "brown" }}>Expired</span>
        ),
    },
    {
      field: "status",
      headerName: "Action",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      // renderCell: (cell) => {
      //   return (
      //     <Box className="flexCenter" sx={{ height: "100%" }}>
      //       <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
      //         <img
      //           src={deleted}
      //           style={{ height: "29px", width: "29px" }}
      //           alt="delete"
      //           onClick={() => {
      //             setOpen(true);
      //             setActionParams(cell?.row?.id);
      //           }}
      //         />

      //         <img
      //           src={view}
      //           // onClick={() => {
      //           //   setPostDetailsId(cell?.row?.id);
      //           //   setOpenDetailsModal(cell?.row);
      //           // }}
      //           onClick={() =>
      //             navigate(RouteList.listDetails, {
      //               state: { id: cell?.row?.id },
      //             })
      //           }
      //           style={{ height: "29px", width: "29px" }}
      //           alt="View"
      //         />
      //       </Box>
      //     </Box>
      //   );
      // },
      renderCell: (cell) => {
        const menuOptions = [
          {
            action: () => navigate(RouteList.listDetails, {
              state: { id: cell?.row?.id },
            }),
            title: "Details",
            isDisabled: false,
            isActive: true,
            icon: <DescriptionIcon style={{ color: "#fd5d29" }} />,
          },
          {
            action: () => {
              setOpen(true);
              setActionParams(cell?.row?.id);
            },
            title: "Delete",
            isActive: delete_access ? true : false,
            icon: <DeleteOutlineIcon style={{ color: "#fd5d29" }} />,
          },
        ];
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <BasicMenu rowData={cell?.row} options={menuOptions} />
          </Box>
        );
      },
    },
  ];

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleDeletePost = async (id) => {
    try {
      setLoading(true);
      const res = await AXIOS.get(`${apiList.list.delete}?feed_id=${id}`);
      if (res?.s) {
        toast.error("Deleted successfully");
        getData(
          page,
          rowsPerPage,
          filter,
          filterRef.current,
          debouncedSearchTerm,
          searchRef.current,
          from_date,
          fromDateRef.current,
          to_date,
          toDateRef.current
        );
        setLoading(false);
      } else {
        toast.error(res?.m);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const filterOption = [
    { label: "All", value: 9 },
    { label: "Active", value: "0" },
    { label: "Expired", value: "1" },
  ];

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    search,
    prevSearch,
    from_date,
    prevFromDate,
    to_date,
    prevToDate
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (
        filter !== prevFilter ||
        search !== prevSearch ||
        from_date !== prevFromDate ||
        to_date !== prevToDate
      ) {
        setPage(0);
        setData([]);
      }
      // let arg = search?.length > 0 ? `${filter} and search=${search}` : filter;
      let arg =
        search?.length > 0
          ? `?search=${search}&skip=${count}&limit=${rowsPerPage}`
          : `?skip=${count}&limit=${rowsPerPage}`;

      if (filter !== 9 && filter !== 99) {
        arg += `&is_expired=${filter}`;
      }

      if (from_date && to_date) {
        if (moment(to_date).isSameOrAfter(moment(from_date))) {
          arg += `&from_date=${from_date} &to_date=${to_date}`;
        } else {
          toast.error("last date must be greater than start date");
          setToDate("");
        }
      }

      const res = await AXIOS.get(`${apiList.list?.getall}${arg}&type=2`);
      if (res?.s) {
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        setTotalData(0);
        setPage(0);
        setData([]);
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);
  const fromDateRef = useRef(filter);
  const toDateRef = useRef(filter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      debouncedSearchTerm,
      searchRef.current,
      from_date,
      fromDateRef.current,
      to_date,
      toDateRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
    fromDateRef.current = from_date;
    toDateRef.current = to_date;
  }, [debouncedSearchTerm, filter, to_date, rowsPerPage, page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          m: "auto",
          top: {
            xs: "-105px",
            sm: "-90px",
            md: "-90px",
            lg: "-55px",
            xl: "-55px",
          },
          right: { lg: "0px" },
          minWidth: "300px",
          height: "50px",
          // border: "1px solid red",
          // zIndex: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: "300px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            placeholder="Search by Name and category"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
              maxWidth: "300px",
            }}
            value={searchTerm?.trimStart()}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search"
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <DatePickerComponent
            name={"from_date"}
            value={from_date}
            setValue={setFromDate}
            placeholder={"From Date"}
          />
          <DatePickerComponent
            name={"to_date"}
            value={to_date}
            setValue={setToDate}
            placeholder={"To Date"}
          />
          <Button
            onClick={() => {
              setFromDate("");
              setToDate("");
            }}
            variant="contained"
            sx={{
              maxWidth: "100px",
              textTransform: "none",
              borderRadius: "12px",
              background: `grey`,
              display: from_date && to_date ? "flex" : "none",
            }}
          >
            Clear
          </Button>
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
            bgColor={'#fd5d29'}
            color={'#FFF'}
          />
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 160px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
        <PostDetailsModal
          id={postDetailsId}
          open={openDetailsModal}
          setOpen={setOpenDetailsModal}
        />
        <ConfirmationModal1
          open={open}
          setOpen={setOpen}
          onConfirm={() => handleDeletePost(actionParams)}
          title="Confirm Delete Action"
          message="Are you sure want to delete post?"
          confirmText="Confirm"
          cancelText="Cancel"
        />
      </Box>
    </Box>
  );
};

export default AdvDataScreenTab;
