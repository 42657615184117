import { Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import TPost from "../../Assets/Image/userDetails/Group 8.png";
import TReview from "../../Assets/Image/userDetails/Group 8-1.png";
// import TCoSu from "../../Assets/Image/userDetails/Group 8-2.png";
// import TCoRe from "../../Assets/Image/userDetails/Group 8-3.png";
import back from "../../Assets/Image/back.png";
import Loader from "../../Component/Loader";
import UserPostList from "./UserPostList";
import UserPaymentHistory from "./UserPaymentHistory";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import logo from "../../Assets/Image/question.webp";
import folower from "../../Assets/Image/userDetails/Group 8.png";
import UserAdvList from "./UserAdvScreen";
import { StatusList } from "../../Component/StatusList";
import UserDetailsSidebar from "../../Component/UserDetailsSidebar";
import { RouteList } from "../../Component/RoutesList";
import UserEditModal from "./UserEditModal";
import DropDownComponent from "../../Component/DropDownComponent";
import IndividualNotificationComponent from "../Notification/IndividualNotificationModal";
import DetailsActionButton from "../../Component/DetailsAction";
import BlockIcon from "@mui/icons-material/Block";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import { hasAccess } from "../../Component/CheckAccess";

const UserDetailsScreen = () => {
  const location = useLocation();
  const userId = location?.state?.userId;
  const nav = useNavigate();
  const [data, setData] = useState();
  const [loading, setloading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(99);
  const [key, setKey] = useState(0);

  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
  });

  const filterOption = [
    { label: "All", value: 9 },
    { label: "Active", value: "0" },
    { label: "Expired", value: "1" },
  ];

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const getDetailsById = async (id) => {
    try {
      setloading(true);
      const res = await AXIOS.get(
        `${apiList.user.getDetailById}?user_id=${id}`
      );
      const anaRes = await AXIOS.get(
        `${apiList.user.analyticsTotal}?user_id=${id}`
      );
      if (res?.s) {
        setData({ ...res?.r, ...anaRes?.r });
        setKey((pre) => (pre += 1));
      } else {
        toast.error(res?.m);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const handleUserAction = async (userId, status) => {
    let toastMessage = getStatusMessage(status, "toast", "user");
    try {
      const f = new FormData();
      f.append("status", status);
      f.append("user_id", userId);
      const res = await AXIOS.post(`${apiList.user.action}`, f);
      if (res?.s) {
        toast.success(toastMessage);
        getDetailsById(userId);
      } else {
        toast.error(res?.m);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusMessage = (status, type) => {
    const statusItem = StatusList.find((item) => item.code === status);
    return statusItem
      ? type === "toast"
        ? statusItem.toast
        : statusItem.message
      : "Are you sure?";
  };

  useEffect(() => {
    userId && getDetailsById(userId);
  }, [userId]);

  const getMenuOptionList = (data) => {
    const status = data?.status;
    const isPending = status === 0;
    const isVerified = status === 1;
    const isFlegged = status === -2;
    const isSuspended = status === -3;
    const isBanned = status === -4;
    return [
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isSuspended ? 1 : -3,
            currentStatus: status,
          });
        },
        title: isSuspended ? "Re-Active" : "Suspend",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isSuspended ? (
          <PersonIcon style={{ color: "#fd5d29" }} />
        ) : (
          <PersonOffIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isFlegged ? 1 : -2,
            currentStatus: status,
          });
        },
        title: isFlegged ? "Unflag" : "Flag",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isFlegged ? (
          <ReportOffIcon style={{ color: "#fd5d29" }} />
        ) : (
          <ReportGmailerrorredIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: !isBanned && -4,
            currentStatus: status,
          });
        },
        title: isBanned ? "Banned" : "Ban(permanent)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: <BlockIcon style={{ color: "#fd5d29" }} />,
      },
    ];
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "start",
        alignItems: "start",
        position: "relative",
      }}
    >
      {" "}
      {/* Side bar code */}
      {loading ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Loader />
        </Box>
      ) : (
        <>
          <UserDetailsSidebar
            data={data}
            setActionParams={setActionParams}
            setOpen={setOpen}
            key={key}
          />

          {/* user Details code */}
          <Box
            sx={{
              p: 3,
              height: "100%",
              width: { xs: "100%", lg: "calc(100% - 260px)" },
              boxSizing: "border-box",
              overflow: "scroll",
            }}
          >
            {" "}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            > */}
            <Toolbar sx={{ display: { xs: "block", md: "none" } }} />
            <Box className="flexBetween" sx={{ flexWrap: "wrap" }}>
              <Typography>
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <img
                    src={back}
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => nav(-1)}
                    alt=""
                  />
                  User Details{" "}
                </h2>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <UserEditModal
                  userid={userId}
                  title="edit"
                  getUserData={() => getDetailsById(userId)}
                />
                <IndividualNotificationComponent type={1} userId={userId} />
                <DetailsActionButton options={getMenuOptionList(data)} />
              </Box>
            </Box>
            {/* </Box> */}
            <Box sx={{ height: "calc(100vh - 120px)" }}>
              {loading ? (
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Loader />
                </Box>
              ) : (
                <Box>
                  {/* Cards view */}
                  <Box
                    sx={{
                      pt: 3,
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      flexWrap: "wrap",
                      fontWeight: "500",
                      height: "100%",
                    }}
                  >
                    {/* Post */}
                    <Box
                      sx={{
                        padding: "18px 24px",
                        borderRadius: "15px",
                        width: "276px",
                        background: " #FFF6F3",
                        display: "flex",
                        gap: "18px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "4px",
                          color: "#635f5e",
                        }}
                      >
                        <img
                          src={TPost}
                          alt=""
                          style={{ height: "32px", width: "32px" }}
                        />
                        Total Post
                      </Box>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_post?.active ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Active
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_post?.expired ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Expired
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* advertisement */}
                    <Box
                      sx={{
                        padding: "18px 24px",
                        borderRadius: "15px",
                        width: "276px",
                        background: " #FFF6F3",
                        display: data?.role === 2 ? "flex" : "none",
                        gap: "18px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "4px",
                          color: "#635f5e",
                        }}
                      >
                        <img
                          src={TPost}
                          alt=""
                          style={{ height: "32px", width: "32px" }}
                        />
                        Total Advertisement
                      </Box>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_advertise?.active ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Active
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_advertise?.expired ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Expired
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Review */}
                    <Box
                      sx={{
                        padding: "18px 24px",
                        borderRadius: "15px",
                        width: "276px",
                        background: " #FFF6F3",
                        display: "flex",
                        gap: "18px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "4px",
                          color: "#635f5e",
                        }}
                      >
                        <img
                          src={TReview}
                          alt=""
                          style={{ height: "32px", width: "32px" }}
                        />
                        Total Reviews Written
                      </Box>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_review?.review ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Review Written
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* complaints submitted */}
                    <Box
                      sx={{
                        padding: "18px 24px",
                        borderRadius: "15px",
                        width: "276px",
                        background: "#FFF6F3",
                        display: "flex",
                        gap: "18px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "4px",
                          color: "#635f5e",
                        }}
                      >
                        <img
                          src={folower}
                          alt=""
                          style={{ height: "32px", width: "32px" }}
                        />
                        Total follower
                      </Box>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_follower?.followers ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Followers
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            padding: "4px 12px",
                            borderRadius: "8px",
                            background: "#FFF",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fd5d29",
                              fontSize: "24px",
                            }}
                          >
                            {data?.total_follower?.following ?? 0}
                          </Typography>
                          <Box sx={{ color: "#292d32", fontWeight: "500" }}>
                            Following
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <ConfirmationModal1
                      open={open}
                      setOpen={setOpen}
                      logo={logo}
                      onConfirm={() =>
                        handleUserAction(
                          actionParams.userId,
                          actionParams.status
                        )
                      }
                      title="Confirm Action"
                      message={getStatusMessage(actionParams.status)}
                      confirmText="Confirm"
                      cancelText="Cancel"
                    />
                  </Box>
                  {/* data grid */}
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                          py: 2,
                          px: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        {[
                          { id: 0, lable: "Listing", isVisible: true },
                          {
                            id: 1,
                            lable: "Advertisement",
                            isVisible: data?.role === 2 ? true : false,
                          },
                          // { id: 2, lable: "Payment History", isVisible: true },
                        ]?.map((x, i) => (
                          <Box
                            sx={{
                              display: x?.isVisible ? "flex" : "none",
                              color:
                                currentTab === x?.id ? "#fd5d29" : "#00000099",
                              textDecoration:
                                currentTab === x?.id ? "underline" : "none",
                              cursor: "pointer",
                              "&:hover": {
                                color: "#fd5d29",
                              },
                              fontWeight: 600,
                            }}
                            onClick={() => setCurrentTab(x?.id)}
                          >
                            {x?.lable}
                          </Box>
                        ))}
                      </Box>
                      <Box sx={{}}>
                        <DropDownComponent
                          value={filter}
                          options={filterOption}
                          modal={false}
                          handleChange={handleFilterChange}
                          placeholder={"Status"}
                          check={false}
                          bgColor={"#FD5D2924"}
                          color={"#FD5D29"}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: "calc(100vh - 360px)",
                      }}
                    >
                      {currentTab === 0 ? (
                        <UserPostList
                          filter={filter}
                          userId={userId}
                          key={key}
                        />
                      ) : currentTab === 1 ? (
                        <UserAdvList
                          filter={filter}
                          userId={userId}
                          key={key}
                        />
                      ) : (
                        <UserPaymentHistory key={key} />
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserDetailsScreen;
