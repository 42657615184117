import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { Box, Button } from "@mui/material";

const GrowthChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [data, setData] = useState({});
  const [type, setType] = useState(1);

  const buttonStyle = {};

  const getTrend = async (type) => {
    try
    {
      let res = await AXIOS.get(apiList.dashboard.trend, {
        params: {
          type: type,
        },
      });
      if (res?.s)
      {
        setData(res?.r);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getTrend(type);
  }, [type]);

  useEffect(() => {
    if (chartInstanceRef.current)
    {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");

    chartInstanceRef.current = new Chart(ctx, {
      type: "line",

      data: {
        labels: data?.listing_graph?.map((obj) => Object.keys(obj)[0]),
        datasets: [
          {
            label: "Listing",
            data: data?.listing_graph?.map((obj) => Object.values(obj)[0]),
            borderColor: "#FD5D29",
            backgroundColor: "#FD5D29",
            tension: 0.4,
            pointRadius: 0,
            transitions: 0.5,
          },
          {
            label: "Users",
            data: data?.user_graph?.map((obj) => Object.values(obj)[0]),
            borderColor: "#00000050",
            backgroundColor: "#00000050",
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        // aspectRatio: false,

        plugins: {
          legend: {
            display: true,
          },
        },

        layout: {
          autoPadding: true,
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        font: "Outfit",
        animations: {
          radius: {
            duration: 400,
            easing: "easeInBounce",
            loop: (context) => context.active,
          },
        },
        hoverRadius: 12,
      },
    });

    return () => chartInstanceRef.current.destroy();
  }, [data]);

  return (
    <>
      <canvas ref={chartRef}></canvas>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          gap: 2
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            color: type === 1 ? '#fd5d29' : "#00000090",
          }}
          onClick={() => setType(1)}
        >
          Week
        </Button>
        <Button
          sx={{
            textTransform: "none",
            color: type === 2 ? '#fd5d29' : "#00000090",
          }}
          onClick={() => setType(2)}
        >
          Month
        </Button>
        <Button
          sx={{
            textTransform: "none",
            color: type === 3 ? '#fd5d29' : "#00000090",
          }}
          onClick={() => setType(3)}
        >
          Year
        </Button>
      </Box>
    </>
  );
};

export default GrowthChart;
