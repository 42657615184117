import { DataGrid, gridClasses } from "@mui/x-data-grid";
import NoDataImg from "./NoDataImage";
import { TablePagination } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

function DataListingComponent(props) {
  const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.Mui-odd`]: {
      backgroundColor: "#FD5D2910",
      "&:hover": {
        backgroundColor: alpha("#F3F2F3", 0.4),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },

    [`& .${gridClasses.row}.Mui-even`]: {
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: alpha("#F3F2F3", 0.4),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }));

  const {
    data,
    loading,
    columns,
    totalData = 0,
    page = 0,
    handlePageChange,
    rowsPerPage,
    handleRowsPerPageChange,
    PAGE_SIZE_OPTIONS,
    hideFooter = false,
  } = props;

  return (
    <>
      {
        <>
          <StripedDataGrid
            rows={data ?? []}
            pagination
            loading={loading}
            hideFooter
            getRowId={(row) =>row?.rowid?? row.id}
            disableColumnMenu
            disableAutosize
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "Mui-even"
                : "Mui-odd"
            }
            slots={{ noRowsOverlay: NoDataImg }}
            disableColumnFilter
            columns={columns}
            hideFooterSelectedRowCount
            // getRowHeight={() => rowHeight}
            slotProps={{
              loadingOverlay: {
                variant: "skeleton",
                noRowsVariant: "skeleton",
              },
            }}
            paginationMode="server"
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "12px",
              },
              cursor: "pointer",
              "& .MuiDataGrid-root": {
                rowBorderColor: "black",
              },

              "& .MuiDataGrid-cell": {
                textAlign: "center",
                color: "#292D32",
                borderWidth: "1px 1px 1px 0px",
                borderStyle: "solid",
                borderColor: "#292D3250",
                // background: "#FD5D29",
                "&:focus": { outline: "none" },
              },

              ".MuiDataGrid-cell:focus": { outline: "none" },
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
                color: "#FFF",
              },
              "& .super-app-theme--header": {
                background: "#FD5D29",
                // borderWidth: "0px 1px 1px 1px",
                // borderStyle: "solid",
                // borderColor: "#D8D4FF52",
              },
            }}
            pageSize={rowsPerPage}
          />
          {!hideFooter && (
            <TablePagination
              component="div"
              sx={{
                color: "#FD5D29",
                ".MuiSvgIcon-root": {
                  color: "#FD5D29",
                },
              }}
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          )}
        </>
      }
    </>
  );
}

export default DataListingComponent;
