import { Box, Button, Typography } from "@mui/material";
import financeIcon from "../../Assets/Image/dashList.png";
import ActiveLogsTab from "./Tabs/ActiveLogs";
import { useState, useMemo } from "react";
import DatePickerComponent from "../../Component/DatePicker";
import ListingReportTab from "./Tabs/ListingReportTab";

const ReportsAndLogsScreen = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);

  // Fetch user access roles from localStorage
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const third = accesslist?.find((module) => module.id === 13);

  // Define Access Rights
  const accessRights = {
    Export: !!third?.access?.find(
      (access) => access.id == 30 && access.admin_user_id
    ),
    activity: !!third?.access?.find(
      (access) => access.id == 17 && access.admin_user_id
    ),
    financial: !!third?.access?.find(
      (access) => access.id == 18 && access.admin_user_id
    ),
    business: !!third?.access?.find(
      (access) => access.id == 19 && access.admin_user_id
    ),
    listing: !!third?.access?.find(
      (access) => access.id == 20 && access.admin_user_id
    ),
    engagement: !!third?.access?.find(
      (access) => access.id == 21 && access.admin_user_id
    ),
    platform: !!third?.access?.find(
      (access) => access.id == 22 && access.admin_user_id
    ),
  };

  // Define Reports List with Access Control
  const Reports = useMemo(
    () => [
      {
        id: 0,
        Title: "Activity Logs",
        Icon: financeIcon,
        data: <ActiveLogsTab />,
        access: accessRights.activity,
      },
      {
        id: 1,
        Title: "Financial Reports",
        Icon: financeIcon,
        data: <ActiveLogsTab />,
        access: accessRights.financial,
      },
      {
        id: 2,
        Title: "Business Performance Reports",
        Icon: financeIcon,
        data: <ActiveLogsTab />,
        access: accessRights.business,
      },
      {
        id: 3,
        Title: "Listings Reports",
        Icon: financeIcon,
        data: <ListingReportTab />,
        access: accessRights.listing,
      },
      {
        id: 4,
        Title: "User Engagement Reports",
        Icon: financeIcon,
        data: <ActiveLogsTab />,
        access: accessRights.engagement,
      },
      {
        id: 5,
        Title: "Platform Engagement Trends",
        Icon: financeIcon,
        data: <ActiveLogsTab />,
        access: accessRights.platform,
      },
    ],
    [accessRights]
  );

  // Filter Reports based on User Access
  const accessibleReports = Reports.filter((report) => report.access);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {/* Header Section */}
      <Box className="flexBetween">
        <Typography variant="h4">Reports & Logs</Typography>
        {/* <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <DatePickerComponent
            name="from_date"
            value={from_date}
            setValue={setFromDate}
            placeholder="From Date"
          />
          <DatePickerComponent
            name="to_date"
            value={to_date}
            setValue={setToDate}
            placeholder="To Date"
          />

          {from_date && to_date && (
            <Button
              onClick={() => {
                setFromDate(null);
                setToDate(null);
              }}
              variant="contained"
              sx={{
                maxWidth: "100px",
                textTransform: "none",
                borderRadius: "12px",
                background: "grey",
              }}
            >
              Clear
            </Button>
          )}

          <Button
            variant="contained"
            sx={{
              display: accessRights?.Export ? "block" : "none",
              maxWidth: "100px",
              textTransform: "none",
              borderRadius: "10px",
              background: "#fd5d29",
              color: "#FFF",
              fontWeight: 400,
            }}
          >
            Export
          </Button>
        </Box> */}
      </Box>

      {/* Main Content Section */}
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Left Sidebar (Reports List) */}
        <Box
          sx={{
            width: "280px",
            border: "1px solid #00000024",
            borderRadius: "10px",
            p: 2,
          }}
        >
          {accessibleReports.map((report) => (
            <Box
              key={report.id}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 1,
                p: 1,
                height: "55px",
                my: 2,
                background: activeTab === report.id ? "#FFEEE9" : "#FFF",
                borderWidth: "0.5px 0.5px 2px 0.5px",
                borderStyle: "solid",
                borderColor: "#FD5D29",
                borderRadius: "14px",
                cursor: "pointer",
                "&:hover": { outline: "1px solid grey" },
              }}
              onClick={() => setActiveTab(report.id)}
            >
              <Typography sx={{ fontWeight: 500 }}>{report.Title}</Typography>
            </Box>
          ))}
        </Box>

        {/* Right Content Panel */}
        <Box
          sx={{
            width: "calc(100% - 300px)",
            background: "#F5F5F5",
            p: 2,
            borderRadius: "10px",
          }}
        >
          {accessibleReports[activeTab]?.data}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsAndLogsScreen;
