import { Avatar, Badge, Box, Typography, styled } from "@mui/material";
import React from "react";
import { apiList } from "./apiList";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PhotoIcon from "@mui/icons-material/Photo";

const SmallAvatar = styled(Avatar)(() => ({
  width: 22,
  height: 22,
  border: "2px solid white",
}));

const ChatList = ({ data, setSelectedChatId }) => {
  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

    if (diffInSeconds < 3600) {
      return rtf.format(-Math.floor(diffInSeconds / 60), "minute");
    } else if (diffInSeconds < 86400) {
      return rtf.format(-Math.floor(diffInSeconds / 3600), "hour");
    } else {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  };

  const nameFormator = (name) => {
    if (name) {
      return name?.charAt(0)?.toUpperCase() + name?.slice(1);
    } else {
      return null;
    }
  };
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: "12px",
        bgcolor: data?.chat_id === 1 ? "#FFF" : "#F9FAFB",
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
        transition: "background 0.3s ease",
        "&:hover": { bgcolor: "#ECEFF1" },
        mb: 0.75,
      }}
      onClick={() => setSelectedChatId(data)}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <SmallAvatar
            alt={nameFormator(data?.user_two_details?.username)}
            src={`${
              data?.user_two_details?.profile_img &&
              apiList.baseURL + data?.user_two_details?.profile_img
            }`}
          />
        }
      >
        <Avatar
          sx={{ width: 50, height: 50 }}
          alt={nameFormator(data?.user_one_details?.username) || "User"}
          src={`${
            data?.user_one_details?.profile_img &&
            apiList.baseURL + data?.user_one_details?.profile_img
          }`}
        />
      </Badge>

      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Typography
          variant="body1"
          fontWeight={600}
          sx={{
            color: "#333",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {[data?.user_one_details, data?.user_two_details]
            .map((user) => nameFormator(user?.username))
            .join(", ")}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: data?.last_message?.is_delete === 0 ? "#777" : "red",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 0.5,
            height: "21px",
          }}
        >
          {data?.last_message?.message_type === 2 ? (
            <PhotoIcon style={{ fontSize: "14px" }} />
          ) : (
            data?.last_message?.message_type === 3 && (
              <PlayCircleOutlineIcon style={{ fontSize: "14px" }} />
            )
          )}
          {data?.last_message?.message_type === 1
            ? data?.last_message?.message
            : data?.last_message?.message_type === 2
            ? "Image"
            : data?.last_message?.message_type === 3 && "Video"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          // gap: 0.5,
          // border:'1px solid red',
          height: "100%",
          alignSelf: "start",
          mt: 0.5,
        }}
      >
        <Typography variant="caption" sx={{ color: "#888" }}>
          {data?.last_message?.created_at
            ? formatTimeAgo(data?.last_message?.created_at)
            : null}
        </Typography>
        {/* <Box
          sx={{
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#FD5D29",
            color: "#fff",
            height: "18px",
            width: "18px",
            fontSize: "12px",
          }}
        >
          4
        </Box> */}
      </Box>
    </Box>
  );
};

export default ChatList;
