import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Reach from "../Assets/Image/Reach.png";
import Views from "../Assets/Image/Views.png";
import Clicks from "../Assets/Image/Clicks.png";
import { AXIOS } from "./apiHelper";
import { apiList } from "./apiList";
import { toast } from "react-toastify";

const formatNumber = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "K";
    return num;
};

const AnalyticsCard = ({ icon, label, value, loading }) => (
    <Box
        sx={{
            padding: "18px 24px",
            borderRadius: "15px",
            width: "230px",
            background: "#FFF6F3",
            display: "flex",
            flexDirection: "column",
            gap: "18px",
        }}
    >
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "#635f5e",
            }}
        >
            <img src={icon} alt={label} style={{ height: "32px", width: "32px" }} />
            {label}
        </Box>
        <Box
            sx={{
                padding: "4px 12px",
                borderRadius: "8px",
                background: "#FFF",
                textAlign: "start",
            }}
        >
            <Typography sx={{ color: "#fd5d29", fontSize: "24px" }}>
                {loading ? <CircularProgress size={18} style={{ color: '#fd5d29' }} /> : formatNumber(value)}
            </Typography>
        </Box>
    </Box>
);

const ListAnalyticsCardComponent = ({ id }) => {
    const [data, setData] = useState({ total_reach: 0, total_views: 0, total_clicks: 0 });
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try
            {
                const res = await AXIOS.get(apiList.list.getAnalytics, {
                    params: { feed_id: id },
                });
                if (res?.s)
                {
                    setData(res?.r || {});
                } else
                {
                    toast.warn(res?.m);
                }
                setLoading(false)
            } catch (error)
            {
                setLoading(false)
                toast.error("Failed to fetch analytics data");
                console.error("ListAnalyticsCardComponent Error:", error);
            }
        };

        if (id) fetchData();
    }, [id]);

    // Analytics data configuration
    const analyticsData = [
        { icon: Reach, label: "Total Reach", value: data.total_reach ?? 0 },
        { icon: Views, label: "Total Views", value: data.total_views ?? 0 },
        { icon: Clicks, label: "Total Clicks", value: data.total_clicks ?? 0 },
    ];

    return (
        <Box
            sx={{
                pt: 3,
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexWrap: "wrap",
                fontWeight: 500,
                height: "100%",
            }}
        >
            {analyticsData.map((item, index) => (
                <AnalyticsCard key={index} {...item} loading={loading} />
            ))}
        </Box>
    );
};

export default ListAnalyticsCardComponent;
