import { Box, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { toast } from "react-toastify";
import back from "../../Assets/Image/back.png";
import Loader from "../../Component/Loader";
import UserDetailsSidebar from "../../Component/UserDetailsSidebar";
import BusinessHours from "../BusinessDetails/businessHours";
import ListMediaComponent from "./ListMediaComponent";
import moment from "moment";
import ReviewRatingDataComponent from "./ReviewRatingDataComponent";
import BlockIcon from "@mui/icons-material/Block";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import DetailsActionButton from "../../Component/DetailsAction";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import { StatusList } from "../../Component/StatusList";
import ListAnalyticsCardComponent from "../../Component/ListAnalyticsCardComponent";

const ListDetailsScreen = () => {
  const location = useLocation();
  const postId = location?.state?.id;
  const nav = useNavigate();
  const [data, setData] = useState();
  const [loading, setloading] = useState(true);
  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    is_approve: null,
  });

  const getDetailsById = async (id) => {
    try
    {
      setloading(true);
      const res = await AXIOS.get(`${apiList.list.detailsById}?feed_id=${id}`);
      if (res?.s)
      {
        setData(res?.r);
      } else
      {
        toast.error(res?.m);
      }
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  const getStatusMessage = (status, type) => {
    const statusItem = StatusList.find((item) => item.code === status);
    return statusItem
      ? type === "toast"
        ? statusItem.toast
        : statusItem.message
      : "Are you sure?";
  };

  const getMenuOptionList = (data) => {
    const status = data?.status;
    const isPending = status === 0;
    const isVerified = status === 1;
    const isFlegged = status === -2;
    const isSuspended = status === -3;
    const isBanned = status === -4;
    return [
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isSuspended ? 1 : -3,
            currentStatus: status,
          });
        },
        title: isSuspended ? "Re-Active(user)" : "Suspend(user)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isSuspended ? (
          <PersonIcon style={{ color: "#fd5d29" }} />
        ) : (
          <PersonOffIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: isFlegged ? 1 : -2,
            currentStatus: status,
          });
        },
        title: isFlegged ? "Unflag(user)" : "Flag(user)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: isFlegged ? (
          <ReportOffIcon style={{ color: "#fd5d29" }} />
        ) : (
          <ReportGmailerrorredIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.id,
            status: !isBanned && -4,
            currentStatus: status,
          });
        },
        title: isBanned ? "Banned(user)" : "Ban(permanent)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: true,
        icon: <BlockIcon style={{ color: "#fd5d29" }} />,
      },
    ];
  };

  const handleUserAction = async (userId, status) => {
    let toastMessage = getStatusMessage(status, "toast", "user");
    try
    {
      const f = new FormData();
      f.append("status", status);
      f.append("user_id", userId);
      const res = await AXIOS.post(`${apiList.user.action}`, f);
      if (res?.s)
      {
        toast.success(toastMessage);

        // await getDetailsById(postId);
        nav(-1);
      } else
      {
        toast.error(res?.m);
      }
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    postId && getDetailsById(postId);
  }, [postId]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {/* Side bar code */}
      {loading ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <Loader />
        </Box>
      ) : (
        <>
          <UserDetailsSidebar
            data={{
              ...data?.user_detail,
              user_business: { id: data?.user_business_id },
              feed_id: data?.id,
            }}
            getData={() => getDetailsById(postId)}
          />

          {/* user Details code */}
          <Box
            sx={{
              p: 3,
              height: "100%",
              width: { xs: "100%", md: "calc(100% - 260px)" },
              boxSizing: "border-box",
              overflow: "scroll",
            }}
          >
            <Toolbar sx={{ display: { xs: "block", md: "none" } }} />

            <Box className="flexBetween">
              <Typography>
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <img
                    src={back}
                    style={{ height: "30px", width: "30px", cursor: "pointer" }}
                    onClick={() => nav(-1)}
                    alt=""
                  />{" "}
                  List Details
                </h2>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* <Button
                  fullWidth
                  sx={{
                    borderRadius: "12px",
                    background: "#Fd5d29",
                    textTransform: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    color: "#FFF",
                    width: "100%",
                    padding: "9px 20px",
                  }}
                >
                  Send Notification
                </Button> */}
                <DetailsActionButton
                  options={getMenuOptionList(data?.user_detail)}
                />
              </Box>
            </Box>

            <Box sx={{ height: "calc(100vh - 120px)" }}>
              {loading ? (
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Loader />
                </Box>
              ) : (
                <>
                  <Box>
                    <ListAnalyticsCardComponent id={postId} />

                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          border: "1px solid #febba5",
                          borderRadius: "8px",
                          padding: "20px",
                          margin: "20px 0 0",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          gap: 2,
                          width: {
                            xs: "100%",
                            md: "100%",
                            lg: "calc(100% - 325px)",
                            xl: "calc(100% - 425px)",
                          },
                          flexDirection: { xs: "column-reverse", md: "row" },
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: "250px",
                            background: "#fff6f3",
                            p: 2,
                            borderRadius: "12px",
                            width: {
                              xs: "100%",
                              md: "50%",
                              lg: "calc(100% - 330px)",
                            },
                          }}
                        >
                          {/* <h3 style={{ color: "#fd5d29" }}>Edit Business Hours</h3> */}
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "600" }}
                          >
                            {data?.title}
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{ color: "#2E2514D1", fontSize: "15px" }}
                          >
                            {data?.description}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 2,
                              my: 2,
                            }}
                          >
                            {[
                              {
                                title: "Price",
                                value: "$" + data?.price,
                                isAvailable: data?.price ? true : false,
                              },

                              {
                                title: "Category",
                                value: data?.category_name,
                                isAvailable: data?.category_name ? true : false,
                              },
                              // {
                              //   title: "Sub Category",
                              //   value: data?.category_sub_name,
                              //   isAvailable: data?.category_sub_name
                              //     ? true
                              //     : false,
                              // },
                              {
                                title: "Posted Date",
                                value:
                                  moment(data?.created_at).format("DD/MM/YYYY") ??
                                  "-",
                                isAvailable: data?.created_at ? true : false,
                              },
                              {
                                title: "Exp. Date",
                                value: data?.expired_at
                                  ? moment(data?.expired_at).format("DD/MM/YYYY")
                                  : "-",
                                isAvailable: data?.expired_at ? true : false,
                              },
                              {
                                title: "Status",
                                value:
                                  data?.is_expired === 0 ? "Active" : "Expired",
                                isAvailable: true,
                                bg:
                                  data?.is_expired === 0 ? "#dcffdc" : "#FFE7E5",
                                color: data?.is_expired === 0 ? "green" : "red",
                              },
                            ]?.map((x, i) => (
                              <Box
                                sx={{
                                  display: x?.isAvailable ? "flex" : "none",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  gap: 0.5,
                                  mb: 1,
                                  width: "200px",
                                }}
                              >
                                <Box sx={{ color: "#fd5d29" }}>{x?.title}</Box>
                                <Box
                                  sx={{
                                    fontWeight: 500,
                                    background: x?.bg && x?.bg,
                                    color: x?.color && x?.color,
                                    padding: x?.color && "5px 12px",
                                    borderRadius: "7px",
                                  }}
                                >
                                  {x?.value}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <ListMediaComponent media={data?.feed_media} />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: { md: 2 },
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        {data?.business_hour?.length > 0 && (
                          <BusinessHours
                            data={data?.business_hour}
                            businessId={""}
                            getData={() => getDetailsById(postId)}
                            edit={false}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #febba5",
                        borderRadius: "8px",
                        padding: "20px",
                        margin: "20px 0 0",
                        height: "calc(100vh - 380px)",
                        display: data?.user_detail?.role === 2 ? "block" : "none",
                      }}
                    >
                      <h3 style={{ color: "#fd5d29" }}>Rating & Review</h3>
                      <ReviewRatingDataComponent
                        businessId={data?.user_business_id}
                      />
                    </Box>
                  </Box>
                </>

              )}
              <ConfirmationModal1
                open={open}
                setOpen={setOpen}
                // loading={isActionLoading}
                onConfirm={() =>
                  handleUserAction(actionParams.userId, actionParams.status)
                }
                title="Confirmation"
                message={getStatusMessage(actionParams.status)}
                confirmText="Confirm"
                cancelText="Cancel"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ListDetailsScreen;
