import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChatList from "../../Component/ChatList";
import ChatDetails from "./ChatDetails";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import selectChat from "../../Assets/Image/selectChat.png";
import Nodata from "../../Assets/Image/No data found.png";

const ChatManagement = () => {
  let dataLength = 0;
  const { ref, inView } = useInView();
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [data, setdata] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setloading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const GetChatList = async (count) => {
    try {
      count === 0 && setloading(true);
      setIsLoadingMore(true);
      let res = await AXIOS.get(apiList.chatManagement.getList, {
        params: {
          skip: count ?? 0,
          // limit: 10,
        },
      });
      if (res?.s) {
        setdata((prev) => (count === 0 ? res?.r : [...prev, ...res?.r]));
        count === 0 && setTotalData(res?.total ?? 0);
      } else {
        setdata([]);
        toast.warn(res?.m);
      }
      setloading(false);
      setIsLoadingMore(false);
    } catch (error) {
      console.log(error);
      setloading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    GetChatList(0);
  }, []);

  useEffect(() => {
    if (data.length < totalData && inView) {
      //eslint-disable-next-line
      dataLength = data?.length;
      GetChatList(dataLength);
    }
  }, [inView]);



  return (
    <Box>
      <Box>
        <Typography>
          <h2>Chat Management</h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: { xs: "calc(100dvh - 165px)", md: "calc(100vh - 95px)" },
          boxSizing: "border-box",
          justifyContent: "space-between",
          gap: 1,
          mt: 1,
        }}
      >
        {data?.length > 0 ? (
          <>
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", md: "330px" },
                backgroundColor: "#F6F6F6",
                height: "100%",
                borderRadius: "16px",
                boxSizing: "border-box",
                p: 1.5,
                // outline: "1px solid red",
                overflow: "auto",
                display: { xs: selectedChatId ? "none" : "block", md: "block" },
              }}
            >
              {data &&
                data.length > 0 &&
                data?.map((chat) => (
                  <ChatList data={chat} setSelectedChatId={setSelectedChatId} />
                ))}

              <div
                ref={ref}
                style={{
                  visibility: !loading && isLoadingMore ? "visible" : "hidden",
                  textAlign: "center",
                }}
              >
                <CircularProgress size={22} style={{ color: "#fd5d29" }} />
              </div>
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "calc(100% - 350px)",
                },
                // backgroundColor: "#FD5D290F",
                height: "100%",
                borderRadius: "16px",
                display: { xs: selectedChatId ? "block" : "none", md: "block" },
              }}
            >
              {selectedChatId ? (
                <ChatDetails
                  data={selectedChatId}
                  onBack={() => setSelectedChatId(null)}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: {
                      xs: "calc(100dvh - 165px)",
                      md: "calc(100vh - 95px)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <img
                      src={selectChat}
                      style={{ height: "100px", width: "100px" }}
                      alt={`select Chat`}
                    />
                    <Typography
                      variant=""
                      style={{
                        color: "#00000060",
                        textTransform: "none",
                      }}
                    >
                      Select Chat to Continue
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              height: "calc(100vh - 100px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fd5d29" }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <img
                  src={Nodata}
                  style={{ height: "100px", width: "100px", opacity: 0.5 }}
                  alt={`select Chat`}
                />
                <Typography
                  variant=""
                  style={{
                    color: "#00000060",
                    textTransform: "none",
                  }}
                >
                  No chat found
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatManagement;
