import { Box, Typography } from "@mui/material";
import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import DropDownComponent from "../../Component/DropDownComponent";
import { toast } from "react-toastify";
import { apiList } from "../../Component/apiList";
import { AXIOS } from "../../Component/apiHelper";
import logo from "../../Assets/Image/question.webp";
import defaultUserImage from "../../Assets/Image/default.webp";
import app from "../../Assets/Image/User Block-1.webp";
import rej from "../../Assets/Image/User Block.webp";
// import view from "../../Assets/Image/newView.webp";
import { StatusList } from "../../Component/StatusList";
import BasicMenu from "../../Component/MenuOptioinComponent";
import { RouteList } from "../../Component/RoutesList";
import { useNavigate } from "react-router-dom";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import DescriptionIcon from "@mui/icons-material/Description";
import BlockIcon from "@mui/icons-material/Block";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import { hasAccess } from "../../Component/CheckAccess";

const BusinessScreen = () => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState(99);
  const [accountFilter, setAccountFilter] = useState(99);
  const [TypeFilter, setTypeFilter] = useState(99);

  const [open, setOpen] = useState(false);
  const [actionParams, setActionParams] = useState({
    userId: null,
    status: null,
    is_approve: null,
  });
  const navigate = useNavigate();
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };



  const AccountFilterOption = [
    { label: "All", value: 9 },
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "0",
    },
  ];

  const filterOption = [
    { label: "All", value: 9 },
    { label: "Flagged", value: "-2" },
    { label: "Suspended", value: "-3" },
    { label: "Banned", value: "-4" },
  ];

  const TypeFilterOption = [
    { label: "All", value: 9 },
    {
      label: "Pending",
      value: "0",
    },
    {
      label: "Approved",
      value: "1",
    },
    {
      label: "Rejected",
      value: "-1",
    },
  ];

  const handleAccountFilterChange = (e) => {
    setAccountFilter(e.target.value);
  };
  const handleTypeFilterChange = (e) => {
    setTypeFilter(e.target.value);
  };

  const handleDetailsNav = (data) =>
    navigate(RouteList.businessDetails, {
      state: {
        businessId: data?.id,
      },
    });
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const third = accesslist?.find((module) => module.id === 4);
  const suspense_access = !!third?.access?.find(
    (access) => access.id === 14 && access.admin_user_id
  );
  const details_View_access = !!third?.access?.find(
    (access) => access.id === 29 && access.admin_user_id
  );
  const flag_access = !!third?.access?.find(
    (access) => access.id === 15 && access.admin_user_id
  );
  const ban_access = !!third?.access?.find(
    (access) => access.id === 16 && access.admin_user_id
  );

  const getMenuOptionList = (data) => {
    const status = data?.status;
    const isPending = status === 0;
    const isVerified = status === 1;
    const isFlegged = status === -2;
    const isSuspended = status === -3;
    const isBanned = status === -4;
    return [
      {
        action: () => handleDetailsNav(data),
        title: "View Details",
        isDisabled: false,
        isActive: details_View_access ? true : false,
        icon: <DescriptionIcon style={{ color: "#fd5d29" }} />,
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.user_id,
            status: isSuspended ? 1 : 3,
            currentStatus: status,
          });
        },
        title: isSuspended ? "Re-Active" : "Suspend",
        isDisabled: isBanned || isPending ? true : false,
        isActive: suspense_access ? true : false,
        icon: isSuspended ? (
          <PersonIcon style={{ color: "#fd5d29" }} />
        ) : (
          <PersonOffIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.user_id,
            status: isFlegged ? 1 : -2,
            currentStatus: status,
          });
        },
        title: isFlegged ? "Unflag" : "Flag",
        isDisabled: isBanned || isPending ? true : false,
        isActive: flag_access ? true : false,
        icon: isFlegged ? (
          <ReportOffIcon style={{ color: "#fd5d29" }} />
        ) : (
          <ReportGmailerrorredIcon style={{ color: "#fd5d29" }} />
        ),
      },
      {
        action: () => {
          setOpen(true);
          setActionParams({
            userId: data?.user_id,
            status: !isBanned && -4,
            currentStatus: status,
          });
        },
        title: isBanned ? "Banned" : "Ban(permanent)",
        isDisabled: isBanned || isPending ? true : false,
        isActive: ban_access ? true : false,
        icon: <BlockIcon style={{ color: "#fd5d29" }} />,
      },
    ];
  };
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "business_logo",
      headerName: "Logo",
      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          onClick={() => details_View_access && handleDetailsNav(cell?.row)}
        >
          <img
            src={
              cell?.row?.business_logo
                ? apiList.baseURL + cell?.row?.business_logo
                : defaultUserImage
            }
            alt="profile"
            style={{ borderRadius: "12px", height: "35px", width: "35px" }}
          />
        </div>
      ),
    },

    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 250,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Owner Name",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
      renderCell: (cell) => cell?.row?.user_detail?.username,
    },
    {
      field: "category_name",
      headerName: "Category",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      flex: 1,
    },
    {
      field: "Account",
      headerName: "Account",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const { is_active } = cell?.row;
        const d =
          is_active === 0
            ? {
                lable: "Inactive",
                color: "grey",
              }
            : {
                lable: "Active",
              };
        return <span style={{ color: d?.color }}>{d?.lable}</span>;
      },
    },

    {
      field: "apprrove",
      headerName: "Approval",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const s = cell?.row?.is_approve;
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            {s === 0 && hasAccess(4, 31) ? (
              <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
                <img
                  src={rej}
                  alt="Block"
                  style={{ height: "25px", width: "25px" }}
                  // onClick={() => handleBusinessAction(cell?.row?.id, 1)}
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: cell?.row?.id,
                      status: null,
                      is_approve: 1,
                    });
                  }}
                />
                <img
                  src={app}
                  style={{ height: "25px", width: "25px" }}
                  alt="View"
                  onClick={() => {
                    setOpen(true);
                    setActionParams({
                      userId: cell?.row?.id,
                      status: null,
                      is_approve: -1,
                    });
                  }}
                />
              </Box>
            ) : s === 1 ? (
              <span
                className="flexCenter"
                style={{
                  background: "#159300",
                  color: "#FFF",
                  py: 2,
                  px: 4,
                  width: "100px",
                  height: "33px",
                  borderRadius: "8px",
                }}
              >
                Approved
              </span>
            ) : (
              s === -1 && (
                <span
                  className="flexCenter"
                  style={{
                    background: "#D00C00",
                    color: "#FFF",
                    py: 2,
                    px: 4,
                    width: "100px",
                    height: "33px",
                    borderRadius: "8px",
                  }}
                >
                  Rejected
                </span>
              )
            )}
          </Box>
        );
      },
    },
    {
      field: "AccountStatus",
      headerName: "Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const { status } = cell?.row;
        const lable = StatusList?.find((x) => x?.code == status);
        return <span style={{ color: lable?.color }}>{lable?.name}</span>;
      },
    },
  ];

  if (details_View_access || ban_access || flag_access) {
    let column = {
      field: "status",
      headerName: "Action",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        const menuOptions = getMenuOptionList(cell?.row);
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <BasicMenu rowData={cell?.row} options={menuOptions} />
          </Box>
        );
      },
    };
    columns?.push(column);
  }

  const getStatusMessage = (status, type, isFor) => {
    const statusItem = StatusList.find((item) => item.code === status);
    return statusItem
      ? type === "toast"
        ? statusItem.toast
        : statusItem.message
      : "Are you sure?";
  };

  const handleBusinessAction = async (id, status, is_approve) => {
    let toastMessage = getStatusMessage(status, "toast", "business");
    const f = new FormData();
    if (is_approve) {
      f.append("user_business_id", id);
      f.append("is_approve", is_approve);
    } else {
      f.append("status", status);
      f.append("user_id", id);
    }

    try {
      setIsActionLoading(true);
      let url = is_approve
        ? apiList.business.updateBusiness
        : apiList.user.action;
      const res = await AXIOS.post(url, f);

      if (res?.s) {
        if (is_approve) {
          is_approve === 1
            ? toast.success("Approved")
            : toast.error("Rejected");
        } else {
          toast.success(toastMessage);
        }
        getData(
          page,
          rowsPerPage,
          filter,
          filterRef.current,
          accountFilter,
          accountFilterRef.current,
          TypeFilter,
          typeFilterRef.current,
          debouncedSearchTerm,
          searchRef.current
        );
        setIsActionLoading(false);
      } else {
        setIsActionLoading(false);
        toast.error(res?.m);
      }
      setOpen(false);
    } catch (error) {
      setIsActionLoading(false);
      setOpen(false);
      console.log(error);
    }
  };

  const getData = async (
    page = 0,
    rowsPerPage = PAGE_SIZE_OPTIONS[0],
    filter,
    prevFilter,
    accountFilter,
    preAccountFilter,
    TypeFilter,
    preTypeFilterRef,
    search,
    prevSearch
  ) => {
    try {
      const count = page * rowsPerPage;
      setLoading(true);
      if (
        filter !== prevFilter ||
        search !== prevSearch ||
        accountFilter !== preAccountFilter ||
        TypeFilter !== preTypeFilterRef
      ) {
        setPage(0);
        setData([]);
      }

      let endPoint = `?skip=${count}&limit=${PAGE_SIZE_OPTIONS}`;
      if (search?.length > 0) {
        endPoint += `&search=${search?.trim()}`;
      }
      if (filter !== 99 && filter !== 9) {
        endPoint += `&status=${filter}`;
      }
      if (accountFilter !== 99 && accountFilter !== 9) {
        endPoint += `&is_active=${accountFilter}`;
      }
      if (TypeFilter !== 99 && TypeFilter !== 9) {
        endPoint += `&is_approve=${TypeFilter}`;
      }

      const res = await AXIOS.get(`${apiList.business.getAll}${endPoint}`);
      if (res?.s) {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        setData(serialData);
        page === 0 && setTotalData(res?.total ?? 0);
        setLoading(false);
      } else {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const searchRef = useRef(debouncedSearchTerm);
  const filterRef = useRef(filter);
  const accountFilterRef = useRef(accountFilter);
  const typeFilterRef = useRef(TypeFilter);

  useEffect(() => {
    getData(
      page,
      rowsPerPage,
      filter,
      filterRef.current,
      accountFilter,
      accountFilterRef.current,
      TypeFilter,
      typeFilterRef.current,
      debouncedSearchTerm,
      searchRef.current
    );
    searchRef.current = debouncedSearchTerm;
    filterRef.current = filter;
    accountFilterRef.current = accountFilter;
    typeFilterRef.current = TypeFilter;
  }, [
    debouncedSearchTerm,
    accountFilter,
    TypeFilter,
    filter,
    rowsPerPage,
    page,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box className="flexBetween">
        <Typography>
          <h2> Business Management </h2>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "470px",
            width: "100%",
          }}
        >
          <input
            type="search"
            name="search"
            value={searchTerm?.trimStart()}
            placeholder="Search by name, category and email"
            style={{
              padding: "13px",
              height: "100%",
              borderRadius: "12px",
              border: "1px solid #FD5D29",
              backgroundColor: "#FFF6F3",
              color: "#FD5D29",
              width: "100%",
            }}
            onChange={(e) => setSearchTerm(e.target.value?.trimStart())}
            id="search"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DropDownComponent
            value={accountFilter}
            options={AccountFilterOption}
            modal={false}
            handleChange={handleAccountFilterChange}
            placeholder={"Account"}
            check={false}
            bgColor={"#fd5d2924"}
            color={"#fd5d29"}
          />

          <DropDownComponent
            value={TypeFilter}
            options={TypeFilterOption}
            modal={false}
            handleChange={handleTypeFilterChange}
            placeholder={"Action"}
            check={false}
            bgColor={"#fd5d2924"}
            color={"#fd5d29"}
          />
          <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
            bgColor={"#fd5d29"}
            color={"#FFF"}
          />
          {/* <DropDownComponent
            value={filter}
            options={filterOption}
            modal={false}
            handleChange={handleFilterChange}
            placeholder={"Status"}
            check={false}
          /> */}
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 180px)" }}>
        <DataListingComponent
          data={data}
          PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
          loading={loading}
          totalData={totalData}
          page={page}
          columns={columns}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        logo={logo}
        loading={isActionLoading}
        onConfirm={() =>
          handleBusinessAction(
            actionParams.userId,
            actionParams.status,
            actionParams.is_approve
          )
        }
        title="Confirmation"
        message={
          actionParams.status
            ? getStatusMessage(actionParams.status)
            : `Are you sure to ${
                actionParams.is_approve === 1 ? "Approve." : "Reject."
              }?`
        }
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </Box>
  );
};

export default BusinessScreen;
