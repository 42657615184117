import DataListingComponent from "../../Component/DataListGrid";
import { useEffect, useRef, useState } from "react";
import { apiList } from "../../Component/apiList";
import deleted from "../../Assets/Image/delete.webp";
import view from "../../Assets/Image/newView.webp";
import { AXIOS } from "../../Component/apiHelper";
import { toast } from "react-toastify";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import ConfirmationModal1 from "../../Component/ConfirmModal1";
import PostDetailsModal from "../List(Post)/PostDetailsModal";
import { RouteList } from "../../Component/RoutesList";
import { useNavigate } from "react-router-dom";
import BasicMenu from "../../Component/MenuOptioinComponent";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DescriptionIcon from "@mui/icons-material/Description";

const UserAdvList = ({ filter, userId }) => {
  const PAGE_SIZE_OPTIONS = [15];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [actionParams, setActionParams] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [postDetailsId, setPostDetailsId] = useState(0);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 60,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => {
        return cell?.row?.rowid + page * rowsPerPage;
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          onClick={() =>
            navigate(RouteList.listDetails, {
              state: { id: cell?.row?.id },
            })
          }
        >
          <img
            src={
              cell?.row?.feed_media[0]?.thumb
                ? apiList.baseURL + cell?.row?.feed_media[0]?.thumb
                : "https://www.dummyimage.co.uk/50x50/cbcbcb/959595/post/15"
            }
            alt="media"
            style={{ borderRadius: "9px", height: "40px", width: "40px" }}
          />
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Post Title",
      minWidth: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title={params.value || ""} arrow>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },

    {
      field: "category_name",
      headerName: "Category",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    // {
    //   field: "category_sub_name",
    //   headerName: "Sub Category",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   display: "flex",
    //   align: "center",
    // },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.user_detail?.username
          ? cell?.row?.user_detail?.username
          : "-",
    },
    {
      field: "businessname",
      headerName: "Business Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.user_business?.business_name
          ? cell?.row?.user_business?.business_name
          : "-",
    },

    {
      field: "price",
      headerName: "Price",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => (cell?.row?.price ? "$" + cell?.row?.price : "-"),
    },

    {
      field: "created_at",
      headerName: "Posted Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) => moment(cell?.row?.created_at).format("DD/MM/YYYY"),
    },
    {
      field: "expired_at",
      headerName: "Expiry Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.expired_at
          ? moment(cell?.row?.expired_at).format("DD/MM/YYYY")
          : "-",
    },
    {
      field: "is_expired",
      headerName: "Status",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (cell) =>
        cell?.row?.is_expired === 0 ? (
          <span style={{ color: "green" }}>Active</span>
        ) : (
          <span style={{ color: "brown" }}>Expired</span>
        ),
    },
    {
      field: "status",
      headerName: "Action",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      align: "center",
      // renderCell: (cell) => {
      //   return (
      //     <Box className="flexCenter" sx={{ height: "100%" }}>
      //       <Box className="flexCenter" sx={{ height: "100%", gap: 1.5 }}>
      //         <img
      //           src={deleted}
      //           style={{ height: "29px", width: "29px" }}
      //           alt="delete"
      //           onClick={() => {
      //             setOpen(true);
      //             setActionParams(cell?.row?.id);
      //           }}
      //         />

      //         <img
      //           src={view}
      //           // onClick={() => {
      //           //   setPostDetailsId(cell?.row?.id);
      //           //   setOpenDetailsModal(cell?.row);
      //           // }}
      //           onClick={() =>
      //             navigate(RouteList.listDetails, {
      //               state: { id: cell?.row?.id },
      //             })
      //           }
      //           style={{ height: "29px", width: "29px" }}
      //           alt="View"
      //         />
      //       </Box>
      //     </Box>
      //   );
      // },
      renderCell: (cell) => {
        const menuOptions = [
          {
            action: () => navigate(RouteList.listDetails, {
              state: { id: cell?.row?.id },
            }),
            title: "Details",
            isDisabled: false,
            // isActive: user_details_access ? true : false,
            icon: <DescriptionIcon style={{ color: "#fd5d29" }} />,
          },
          {
            action: () => {
              setOpen(true);
              setActionParams(cell?.row?.id);
            },
            title: "Delete",
            // isDisabled: isBanned || isPending ? true : false,
            isActive: true,
            icon: <DeleteOutlineIcon style={{ color: "#fd5d29" }} />,
          },
        ];
        return (
          <Box className="flexCenter" sx={{ height: "100%" }}>
            <BasicMenu rowData={cell?.row} options={menuOptions} />
          </Box>
        );
      },
    },
  ];
  const getData = async (id, page, rowsPerPage, filter, prevFilter) => {
    try
    {
      if (filter !== prevFilter)
      {
        setPage(0);
        setData([]);
      }
      let count = page * rowsPerPage;
      setLoading(true);
      let arg = "&type=2";
      if (filter !== 9 && filter !== 99)
      {
        arg += `&is_expired=${filter}`;
      }
      const res = await AXIOS.get(
        `${apiList.list.getall}?user_id=${id}${arg}&skip=${count}&limit=${rowsPerPage}`
      );
      if (res?.s)
      {
        // const updatedData = count === 0 ? res?.r : [...data, ...res?.r];
        const updatedData = res?.r;
        const serialData = updatedData?.map((x, i) => {
          return { ...x, rowid: i + 1 };
        });
        page === 0 && setTotalData(res?.total ?? 0);
        setData(serialData);
        setLoading(false);
      } else
      {
        toast.warn(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeletePost = async (id) => {
    try
    {
      setLoading(true);
      const res = await AXIOS.get(`${apiList.list.delete}?feed_id=${id}`);
      if (res?.s)
      {
        toast.error("Deleted successfully");
        getData(userId, page, rowsPerPage, filter, filterRef.current);
        setLoading(false);
      } else
      {
        toast.error(res?.m);
        setLoading(false);
      }
    } catch (error)
    {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const filterRef = useRef(filter);

  useEffect(() => {
    getData(userId, page, rowsPerPage, filter, filterRef.current);
    filterRef.current = filter;
  }, [userId, page, rowsPerPage, filter]);

  return (
    <>
      <DataListingComponent
        data={data}
        PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
        loading={loading}
        totalData={totalData}
        page={page}
        columns={columns}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      <PostDetailsModal
        id={postDetailsId}
        open={openDetailsModal}
        setOpen={setOpenDetailsModal}
      />

      <ConfirmationModal1
        open={open}
        setOpen={setOpen}
        onConfirm={() => handleDeletePost(actionParams)}
        title="Confirm Delete Action"
        message="Are you sure want to delete post?"
        confirmText="Confirm"
        cancelText="Cancel"
        loading={loading}
      />
    </>
  );
};

export default UserAdvList;
