import { Box, Button, Chip, Typography } from "@mui/material";
import AnalyticCards from "./AnalyticCards";
import RecentBusinessComponent from "./RecentBusinessComponent";
import { useNavigate } from "react-router-dom";
import { RouteList } from "../../Component/RoutesList";
import GrowthChart from "./GrowthChart";
import { AXIOS } from "../../Component/apiHelper";
import { apiList } from "../../Component/apiList";
import { useEffect, useState } from "react";

const DashboardScreen = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const accesslist = JSON.parse(
    localStorage.getItem("Megalopolist-userData-roles")
  );
  const UserData = JSON.parse(localStorage.getItem("Megalopolist-userData"));
  const firstModule = accesslist?.find((module) => module.id === 1);
  const recent_user_access = !!firstModule?.access?.find(
    (access) => access.id === 1 && access.admin_user_id
  );
  const recent_business_access = !!firstModule?.access?.find(
    (access) => access.id === 9 && access.admin_user_id
  );
  const recent_trend_access = !!firstModule?.access?.find(
    (access) => access.id === 6 && access.admin_user_id
  );
  const recent_New_User_access = !!firstModule?.access?.find(
    (access) => access.id === 8 && access.admin_user_id
  );
  const recent_New_alert_access = !!firstModule?.access?.find(
    (access) => access.id === 7 && access.admin_user_id
  );

  const fetchData = async () => {
    try {
      let res = await AXIOS.get(apiList.dashboard.analytics);
      setData(res.r);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: 1.5,
        // border: "1px solid red",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          width: "100%",
          alignItems: "center",
        }}
      >
        <h2> Dashboard </h2>
        <Chip
          label={`Hi, ${UserData?.username}! Welcome back!`}
          sx={{
            backgroundColor: "#fd5d29",
            color: "#FFF",
            borderRadius: "22px",
            padding: "5px 15px",
            fontWeight: "bold",
            fontSize: "14px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Typography>
      <AnalyticCards loading={loading} data={data} setData={setData} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
          // height: { xs: "", lg: "500px" },
          gap: 2,
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            display: recent_trend_access ? "block" : "none",
          }}
        >
          <Typography
            style={{
              color: "#00000099",
            }}
          >
            <h3> Trend Visualization </h3>
          </Typography>
          <Box
            sx={{
              width: {
                xs: "375px",
                sm: "550px",
                md: "550px",
                lg: "600px",
                xl: "700px",
              },
              background: "#f8f8f8",
              border: "1px solid #D2D2D2",
              borderRadius: "20px",
              p: 2,
              mt: 1,
            }}
          >
            <GrowthChart />
          </Box>
        </Box>

        <Box
          sx={{
            width: "350px",
            display: recent_New_User_access ? "block" : "none",
          }}
        >
          <Typography
            style={{
              color: "#00000099",
            }}
          >
            <h3> Recent Alerts</h3>
          </Typography>
          <Box
            sx={{
              background: "#f8f8f8",
              borderRadius: "12px",
              p: 2.5,
              mt: 1,
              display: recent_New_User_access ? "block" : "none",
            }}
          >
            <Typography style={{ fontWeight: 500 }}>New Approval</Typography>
            <Typography
              style={{
                color: "#00000050",
              }}
            >
              {data?.new_user_approval} awaiting approval
            </Typography>
            <Box>
              <Button
                sx={{
                  width: "fit-content",
                  textTransform: "none",
                  borderRadius: "10px",
                  background: "#fd5d29",
                  // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  color: "#FFF",
                  px: 2,
                  mt: 2,
                  "&:hover": {
                    background: "#00075",
                  },
                }}
                onClick={() => nav(RouteList.business)}
              >
                Review
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#f8f8f8",
              borderRadius: "12px",
              p: 2.5,
              mt: 2,
              display: recent_New_alert_access ? "block" : "none",
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Flagged Content</Typography>
            <Typography
              style={{
                color: "#00000050",
              }}
            >
              {data?.flagged_content} items flagged for review
            </Typography>
            <Box>
              <Button
                sx={{
                  width: "fit-content",
                  textTransform: "none",
                  borderRadius: "10px",
                  background: "#fd5d29",
                  // background: `linear-gradient(0deg, #FF602D 8.18%, #DA4312 100%)`,
                  color: "#FFF",
                  px: 2,
                  mt: 2,
                  "&:hover": {
                    background: "#00075",
                  },
                }}
                onClick={() => nav(RouteList.report)}
              >
                Review
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: recent_business_access ? "flex" : "none",
          width: "100%",
          flexWrap: "wrap",
          height: "650px",
          gap: { xs: 4, md: 4, lg: 1 },
          justifyContent: "space-between",
          // border: "1px solid green",
        }}
      >
        {/* <Box
          sx={{
            display: recent_user_access ? "block" : "none",
            width: { xs: "100%", lg: "49.5%" },
            height: "600px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
            <h4>Recent Users</h4>
            <h5
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "400",
              }}
              onClick={() => nav(RouteList.user)}
            >
              View All
            </h5>
          </Box>
          <RecentUserComponent />
        </Box> */}
        <Box
          sx={{
            // display: recent_business_access ? "block" : "none",
            width: { xs: "100%", lg: "100%" },
            height: "600px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
            <h4>Recent Business</h4>
            <h5
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontWeight: "400",
              }}
              onClick={() => nav(RouteList.business)}
            >
              View All
            </h5>
          </Box>
          <RecentBusinessComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardScreen;
